.modal-confirm-yctt {
    &.ant-modal .ant-modal-content .ant-modal-header .ant-modal-title {
        text-transform: capitalize;
        color: #191919;
    }

    .ht-green {
        color: green;
    }

    .hl-red {
        color: red;
    }

    .code {
        font-weight: bold;
        color: #0d6bc1;
    }

    .new-exchange-hl {
        font-weight: bold;
        color: green;
    }

    table {
        thead {
            tr {
                th {
                    padding: 10px 10px;
                }
            }
        }
    }
}

.modal-common-v2 {
    .ant-modal-content {
        .ant-modal-close {
            .ant-modal-close-x {
                width: unset;
                height: unset;
            }
        }

        .ant-modal-header {
            padding: 16px 24px;
            border-bottom: none;
        }

        .ant-modal-body {
            padding: 0 24px;
        }

        .ant-modal-footer {
            padding: 16px 24px;
        }
    }

    &.modalAddToCart .ant-modal-content {
        .ant-modal-close {
            .ant-modal-close-x {
                width: unset;
                height: unset;
            }
        }

        .ant-modal-header {
            padding-right: 16px;
            padding-top: 9px;
            padding-bottom: 9px;
            border-bottom: none;
        }

        .ant-modal-body {
            padding: 0 16px;
        }

        .ant-modal-footer {
            padding: 0;
        }
    }

    &.modal-delete-common .ant-modal-content {
        .ant-modal-header {
            padding: 16px;
        }

        .ant-modal-body {
            padding: 0 16px 0;
        }

        .ant-modal-footer {
            padding: 16px;
        }
    }
}

@media only screen and(max-width: 768px) {
    .update-product-item-modal {
        .ant-modal-body {
            height: 70vh;
            overflow-y: scroll;
        }
    }
}

// .ant-scrolling-effect {
//     touch-action: none;
// }
