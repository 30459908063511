$secondary-color: var(--ant-primary-color);

.datepicker-range {
    &:hover {
        border-color: $secondary-color !important;
    }
    &:focus-within {
        border-color: $secondary-color !important;
        box-shadow: 0 0 0 2px RGB(24 144 255 / 20%);
        outline: 0;
    }
    .datepicker-field {
        position: relative;

        .form-label {
            position: absolute;
            top: 50%;
            left: 16px;
            transform: translateY(-50%);

            user-select: none;
            pointer-events: none;
            transition: 0.25s ease;
        }

        .ant-picker {
            // padding-top: 9px;
            // padding-bottom: 9px;
            padding: 9px 16px;
            &:focus,
            &:focus-within,
            &.input-has-value {
                padding-top: 9px;
                padding-bottom: 9px;

                .ant-picker-suffix {
                    margin-top: -3px;
                }
            }

            .ant-picker-input input:not(:placeholder-shown) + .form-label,
            &:focus-within + .form-label,
            &.input-has-value + .form-label {
                font-size: 12px;
                font-weight: 400;
                top: 0px;
                background: #ffff;
                padding: 0px 4px;
            }

            .ant-picker-input input:not(:placeholder-shown) + .form-label,
            &:focus-within + .form-label {
                color: $secondary-color !important;
            }

            &.input-has-value .ant-picker-clear {
                margin-top: -1px;
            }
        }

        // .ant-picker-input input:not(:placeholder-shown) ~ .ant-picker {
        //     background-color: red !important;
        //     padding-top: 18px;
        //     padding-bottom: 2px;
        // }
    }
}
 

.datepicker-range .ant-picker-focused {
    position: relative;
}

.datepicker-range .ant-picker-focused {
    &::before {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 12px;
        right: 12px;
        width: calc(100% - 24px);
        height: 1px;

        background-color: $secondary-color;
    }
}