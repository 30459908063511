.boxchat {
    //width: 288px;
    //height: 100%;
    //position: fixed;
    //right: 20px;
    //height: calc(100% - 80px);
    .btn--blue {
        background: var(--ant-primary-color);
        border-color: var(--ant-primary-color);
        height: 30px;
        line-height: 30px;
        width: 50px;
        padding: 0 10px;
        text-align: center;
        @include border-radius(3px);
    }
    &__textarea {
        border: 0;
        padding: 0;
        padding-right: 10px;
        font-size: $txt-size-h7;
        color: $txt-color-black;
        font-family: $robotofont;
        font-weight: 300;
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
    .ant-tabs-tab-active {
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 6px;
            background-color: $bg-color-blue;
            bottom: 0;
            left: 0;
        }
    }
    &__content {
    }
    .boxchatwrapper {
        height: 100%;
        overflow: auto;
        // margin-top: 15px;
        // margin-bottom: 15px;
        padding-top: 0;
        &::-webkit-scrollbar-track {
            @include border-radius(20px);
            background-color: #ffffff;
        }

        &::-webkit-scrollbar {
            width: 6px;
            background-color: #ffffff;
        }

        &::-webkit-scrollbar-thumb {
            @include border-radius(20px);
            background-color: $bg-color-gray2;
        }
    }
    .ant-tabs-top-bar {
        margin-bottom: 0;
    }
}

.boxchat-fixed {
    position: fixed;
    right: 26px;
    top: 15px;
    //margin-right: 37px;
}

.camera-dropzone {
    display: inline-block;
    width: 38px !important;
    height: 32px !important;
    color: $txt-color-gray;
    > div {
        border: none !important;
        width: 38px !important;
        height: 32px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.import-dropzone {
    display: inline-block;
    width: 100% !important;
    height: 32px !important;
    color: $txt-color-gray;
    > div {
        border: none !important;
        width: 100% !important;
        height: 32px !important;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .ant-input {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }
    .ant-btn {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
}

//@media only screen and(max-width: 1024px){
//	//.boxchat{
//	//	width: 250px;
//	//}
//	.boxchat-fixed {
//		//margin-right: 20px;
//	}
//}
