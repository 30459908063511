.orderdetail{
	&__top{
		&_name{
			img{
			}
		}
		.ant-divider{
			margin: 15px 0;
		}
		.listorders__items_right--status{
			min-width: 135px;
		}
		&_name{
			position: relative;
			&:before{
				content: '';
				position: absolute;
				width: 1px;
				height: 100%;
				background-color: #e8e8e8;
				right: -10px;
			}
		}
	}
}
.tabdetailorder{
	padding: 15px;
	.ant-list-header{
		border-bottom: 0;
		padding-bottom: 0;
	}
	&__items{

	}
}
.wrappertabs{
	.ant-tabs-nav{
		.ant-tabs-tab{
			color: $txt-color-gray;
			font-size: $txt-size-h7;
			font-family: $robotofont;
			font-weight: 400;
			padding-left: 15px;
			padding-right: 15px
		}
		.ant-tabs-tab-active{
			color: $txt-color-blue;
		}
	}
}

//@media only screen and(max-width: 1024px){
//	.orderdetail{
//		&__top{
//			.listorders__items_right--status{
//				min-width: 110px;
//				font-size: 12px;
//				padding: 0;
//			}
//		}
//	}
//
//	.cartitemavatar{
//		width: 45px!important;
//		height: 45px!important;
//	}
//
//}
