.header{
	padding: 0;
	background-color: transparent;
	&__right{
		&_item{
			position: relative;
			&:before{
				content: "";
				position: absolute;
				width: 1px;
				height: 16px;
				background-color: #D1D1D1;
				right: -13px;
				top: 50%;
				margin-top: -8px;
			}
		}
		.ant-badge-count{
			min-width: 20px;
			height: 12px;
			font-size: 10px;
			line-height: 14px;
		}

	}
}
