.modal-confirm-payment-taobao-global {
    .ant-modal-content {
      border-radius: 12px;
    }
    .ant-modal-content .ant-modal-header {
      border-radius: 12px;
      padding: 16px 24px 0px;
      border-bottom: unset;
    }
  
    .ant-modal-body {
      padding-bottom: 0;
      padding-top: 16px;
    }
  
    .ant-modal-footer {
      padding: 20px 24px 24px;
      display: flex;
      justify-content: flex-end;
      border: 0;
      button {
          border-radius: 4px;
      }
    }
  }
  