.custom-quill {
    border: 1px solid #f0f0f0;
    .ql-toolbar.ql-snow {
        border: none;
        border-bottom: 1px solid #f0f0f0;
    }
    .ql-container.ql-snow {
        border: none;

        .ql-editor {
            min-height: 80px;
            max-height: 200px;
            overflow: auto;
        }
    }
}
