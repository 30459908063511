.formeditaddress{
	.ant-form-item{
		margin-bottom: 10px;
		textarea{
			margin-bottom: 0;
		}
	}
	.ant-form-item-control{

	}
}
.modalform{
	.ant-modal-close{
		right: 9px;
		top: 10px;
	}
	.ant-modal-content{
		padding-bottom: 15px;
		.ant-modal-header{
			border: 0;
			padding-top: 30px;
			.ant-modal-title{
				font-size: $txt-size-h1;
				font-weight: 400;
				color: $txt-color-black;
				text-transform: uppercase;
				a{
					text-transform: capitalize;
				}
			}
		}
		.ant-modal-body{
			padding-top: 10px;
			padding-bottom: 0;
		}
		.ant-modal-footer{
			padding: 10px 24px;
			border: 0;
			.ant-btn{
				padding: 0 57px!important;
				height: 40px;
				line-height: 40px;
				font-size: $txt-size-h7;
				font-family: $robotofont;
				font-weight: 700;
				color: $txt-color-gray2;
			}
			.ant-btn-primary{
				color: $txt-color-white;
			}
		}
		.ant-table-body{
			border: 1px solid #E9E9E9;
			border-bottom: 0;
			@include border-radius(4px);
			.ant-table-thead{
				tr{
					th{
						font-size: 14px;
						color: #9E9E9E;
						font-family: $robotofont;
						font-weight: 400;
					}
				}
			}
		}
	}
}

input[type=email], input[type=text],input[type=password], select, textarea {
	// height: 32px;
	// line-height: 32px;
	//border: 1px solid #c7c7c7!important;
	// border-radius: 4px !important;
}
.ant-input-password{
	padding: 0;
	padding-right: 10px;
	border-radius: 4px;
	input{
		padding-left: 10px!important;
		margin-right: 5px;
		&:focus{
			box-shadow: none!important;
		}
	}
}
.ant-form-item-explain-error{
	font-size: $txt-size-h8;
	margin-top: 5px;
	margin-bottom: 10px;

}
.ant-modal{
	.ant-modal-content{
		@include border-radius(8px);
		.ant-modal-header{
			@include border-radius(8px);
			.ant-modal-title{
				color: $txt-color-blue;
			}
		}
		.ant-modal-body{
			.ant-form-item{
				margin-bottom: 16px;
			}
			.ant-form-item-label {
				> label{
					color: $txt-color-black2;
					font-size: $txt-size-h7;
				}
			}
		}
		.ant-modal-footer{
			padding: 20px 24px 24px;
			display: flex;
			justify-content: flex-end;
			border: 0;
		}
	}

}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
	content: "";
	display: none;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after{
	display: inline-block;
	margin-right: 4px;
	color: #ff4d4f;
	font-size: 14px;
	font-family: SimSun, sans-serif;
	line-height: 1;
	content: '*';
	right: 0;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused, .ant-input:focus, .ant-input-focused{
	border-color: var(--ant-primary-color)!important;
}
