@tailwind base;
@tailwind components;
@tailwind utilities;

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: inline-block;
}


body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  color: #1a1a1a;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
  height: 100px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}