//Font Family
$robotofont: 'Roboto', sans-serif;

//Font Size
$txt-size-h0: 36px;
$txt-size-h1: 24px;
$txt-size-h2: 22px;
$txt-size-h3: 20px;
$txt-size-h4: 18px;
$txt-size-h5: 16px;
$txt-size-h6: 15px;
$txt-size-h7: 14px;
$txt-size-h8: 12px;
$txt-size-h9: 10px;

//Color
$txt-color-white: #ffffff;
$txt-color-black: #000000;
$txt-color-black2: #333333;
$txt-color-black3: #707070;
$txt-color-gray: rgba(0,0,0,0.45);
$txt-color-gray2: rgba(0,0,0,0.25);
$txt-color-gray3: #C3C2C2;
$txt-color-red: #F5222D;
$txt-color-red2: #CF1322;
$txt-color-orange: #faad14;
$txt-color-blue: var(--ant-primary-color);
$txt-color-green: #389E0D;
$txt-color-disabled: #B1B1B1;

//Background Color
$bg-color-white: #ffffff;
$bg-color-yellow: #FFFBE6;
$bg-color-black3: #707070;
$bg-color-blue: var(--ant-primary-color);
$bg-color-blue2: #0f71cc;
$bg-color-blue3: #a6dbff;
$bg-color-blue4: var(--ant-primary-color);
$bg-color-gray: #FAFAFA;
$bg-color-gray2: #D9D9D9;
$bg-color-gray3: #E8E8E8;
$bg-color-gray4: #C7C7C7;
$bg-color-light: #EBEBEB;
//Border Color

$primary-color: #3059a8;
$secondary-color: #0D6BC1;
$light-color: #EBEBEB;
$gray-color: #C0C0C0;
$error-color: #FF4559;
$table-heading-background-color: #F4F6F8;
$txt-default-color: #1A1A1A;
