.nav{
    border-bottom: 1px solid #e8e8e8;
    line-height: 60px;
    height: 60px;
    margin-bottom: 30px;
    ul{
    	background-color: transparent;
    	line-height: 60px;
    	border-bottom: 0;
    	li{
    		padding: 0 31px;
    	}
    }
}