h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0;
}
body {
    overflow-y: auto;
}
#root,
#app-main,
.main-body,
.ant-layout {
    height: 100%;
    &::-webkit-scrollbar-track {
        @include border-radius(20px);
        background-color: #ffffff;
    }

    &::-webkit-scrollbar {
        width: 6px;
        background-color: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
        @include border-radius(20px);
        background-color: $bg-color-gray2;
    }
}
//Color
.txt-color-blue {
    color: $txt-color-blue;
}
.txt-color-black {
    color: $txt-color-black;
}
.txt-color-black2 {
    color: $txt-color-black2;
}
.txt-color-black3 {
    color: $txt-color-black3;
}
.txt-color-white {
    color: $txt-color-white;
}
.txt-color-gray {
    color: $txt-color-gray;
}
.txt-color-gray3 {
    color: $txt-color-gray3;
}
.txt-color-red {
    color: $txt-color-red;
}
.txt-color-red2 {
    color: $txt-color-red2;
}
.txt-color-green {
    color: $txt-color-green;
}
.txt-color-orange {
    color: $txt-color-orange;
}

//Font

.robotoregular {
    font-family: $robotofont;
    font-weight: 400;
}
.robotomedium {
    font-family: $robotofont;
    font-weight: 500;
}
.robotobold {
    font-family: $robotofont;
    font-weight: 700;
}

//Background color
.bg-color-gray {
    background-color: $bg-color-gray;
}
.bg-color-gray2 {
    background-color: $bg-color-gray2;
}
.bg-color-gray3 {
    background-color: $bg-color-gray3;
}
.bg-color-black3 {
    background-color: $bg-color-black3;
}
.bg-color-white {
    background-color: $bg-color-white;
}
.bg-color-blue {
    background-color: $bg-color-blue;
}
.bg-color-blue2 {
    background-color: $bg-color-blue2;
}
.bg-color-blue3 {
    background-color: $bg-color-blue3;
}
.bg-color-blue4 {
    background-color: $bg-color-blue4;
}
.bg-color-green {
    background-color: $txt-color-green;
}

//Font-size
.txt-size-h0 {
    font-size: $txt-size-h0;
}
.txt-size-h1 {
    font-size: $txt-size-h1;
}
.txt-size-h2 {
    font-size: $txt-size-h2;
}
.txt-size-h3 {
    font-size: $txt-size-h3;
}
.txt-size-h4 {
    font-size: $txt-size-h4;
}
.txt-size-h5 {
    font-size: $txt-size-h5;
}
.txt-size-h6 {
    font-size: $txt-size-h6;
}
.txt-size-h7 {
    font-size: $txt-size-h7;
}
.txt-size-h8 {
    font-size: $txt-size-h8;
}
.txt-size-h9 {
    font-size: $txt-size-h9;
}

//Padding
.pd30 {
    padding: 30px;
}
.pd20 {
    padding: 20px;
}
.pd25 {
    padding: 25px;
}
.pd24 {
    padding: 24px;
}
.pd15 {
    padding: 15px;
}
.pd10 {
    padding: 10px;
}
.pd5 {
    padding: 5px;
}
.pd3 {
    padding: 3px;
}

.pdl0 {
    padding-left: 0;
}
.pdl5 {
    padding-left: 5px;
}
.pdl10 {
    padding-left: 10px;
}
.pdl15 {
    padding-left: 15px;
}
.pdl20 {
    padding-left: 20px !important;
}
.pdl25 {
    padding-left: 25px;
}
.pdl24 {
    padding-left: 24px;
}
.pdl30 {
    padding-left: 30px;
}
.pdl35 {
    padding-left: 35px;
}
.pdl40 {
    padding-left: 40px;
}
.pdl45 {
    padding-left: 45px;
}
.pdl50 {
    padding-left: 50px;
}
.pdl55 {
    padding-left: 55px;
}
.pdl65 {
    padding-left: 65px;
}
.pdl60 {
    padding-left: 60px;
}
.pdl70 {
    padding-left: 70px;
}
.pdl80 {
    padding-left: 80px;
}
.pdl95 {
    padding-left: 95px;
}
.pdl100 {
    padding-left: 100px;
}
.pdl200 {
    padding-left: 200px;
}

.pdt140 {
    padding-top: 140px;
}
.pdt120 {
    padding-top: 120px;
}
.pdt80 {
    padding-top: 80px;
}
.pdt70 {
    padding-top: 70px;
}
.pdt60 {
    padding-top: 60px;
}
.pdt50 {
    padding-top: 50px;
}
.pdt40 {
    padding-top: 40px;
}
.pdt35 {
    padding-top: 35px;
}
.pdt30 {
    padding-top: 30px;
}
.pdt25 {
    padding-top: 25px;
}
.pdt20 {
    padding-top: 20px;
}
.pdt15 {
    padding-top: 15px;
}
.pdt10 {
    padding-top: 10px;
}
.pdt7 {
    padding-top: 7px;
}
.pdt5 {
    padding-top: 5px;
}
.pdt2 {
    padding-top: 2px;
}
.pdt3 {
    padding-top: 3px;
}
.pdt4 {
    padding-top: 4px;
}
.pdt0 {
    padding-top: 0;
}

.pdr0 {
    padding-right: 0;
}
.pdr5 {
    padding-right: 5px;
}
.pdr10 {
    padding-right: 10px;
}
.pdr15 {
    padding-right: 15px;
}
.pdr20 {
    padding-right: 20px;
}
.pdr25 {
    padding-right: 25px;
}
.pdr24 {
    padding-right: 24px;
}
.pdr30 {
    padding-right: 30px;
}
.pdr40 {
    padding-right: 40px;
}
.pdr45 {
    padding-right: 45px;
}
.pdr50 {
    padding-right: 50px;
}
.pdr60 {
    padding-right: 60px;
}
.pdr80 {
    padding-right: 80px;
}
.pdr100 {
    padding-right: 100px;
}

.pdbt200 {
    padding-bottom: 100px;
}
.pdbt105 {
    padding-bottom: 105px;
}
.pdbt80 {
    padding-bottom: 80px;
}
.pdbt70 {
    padding-bottom: 70px;
}
.pdbt60 {
    padding-bottom: 60px;
}
.pdbt50 {
    padding-bottom: 50px;
}
.pdbt40 {
    padding-bottom: 40px;
}
.pdbt35 {
    padding-bottom: 35px;
}
.pdbt30 {
    padding-bottom: 30px;
}
.pdbt25 {
    padding-bottom: 25px;
}
.pdbt24 {
    padding-bottom: 24px;
}
.pdbt20 {
    padding-bottom: 20px;
}
.pdbt15 {
    padding-bottom: 15px;
}
.pdbt10 {
    padding-bottom: 10px;
}
.pdbt7 {
    padding-bottom: 7px;
}
.pdbt5 {
    padding-bottom: 5px;
}
.pdbt3 {
    padding-bottom: 3px;
}
.pdbt0 {
    padding-bottom: 0px;
}

//Margin

.mgau {
    margin: auto;
}
.mg25 {
    margin: 25px;
}

.mgbt70 {
    margin-bottom: 70px;
}
.mgbt60 {
    margin-bottom: 60px;
}
.mgbt50 {
    margin-bottom: 50px;
}
.mgbt55 {
    margin-bottom: 55px;
}
.mgbt45 {
    margin-bottom: 45px;
}
.mgbt40 {
    margin-bottom: 40px;
}
.mgbt35 {
    margin-bottom: 35px;
}
.mgbt30 {
    margin-bottom: 30px;
}
.mgbt25 {
    margin-bottom: 25px;
}
.mgbt20 {
    margin-bottom: 20px;
}
.mgbt15 {
    margin-bottom: 15px;
}
.mgbt16 {
    margin-bottom: 16px;
}
.mgbt10 {
    margin-bottom: 10px;
}
.mgbt5 {
    margin-bottom: 5px;
}
.mgbt0 {
    margin-bottom: 0;
}

.mgl30 {
    margin-left: 30px;
}
.mgl25 {
    margin-left: 25px;
}
.mgl20 {
    margin-left: 20px;
}
.mgl15 {
    margin-left: 15px;
}
.mgl12 {
    margin-left: 12px;
}
.mgl10 {
    margin-left: 10px;
}
.mgl50 {
    margin-left: 50px;
}
.mgl5 {
    margin-left: 5px;
}

.mgt-10 {
    margin-top: -10px;
}
.mgt5 {
    margin-top: 5px;
}
.mgt10 {
    margin-top: 10px;
}
.mgt12 {
    margin-top: 12px;
}
.mgt15 {
    margin-top: 15px;
}
.mgt20 {
    margin-top: 20px;
}
.mgt25 {
    margin-top: 25px;
}
.mgt24 {
    margin-top: 24px;
}
.mgt30 {
    margin-top: 30px;
}
.mgt35 {
    margin-top: 35px;
}
.mgt40 {
    margin-top: 40px;
}
.mgt45 {
    margin-top: 45px;
}
.mgt50 {
    margin-top: 50px;
}
.mgt60 {
    margin-top: 60px;
}

.mgr5 {
    margin-right: 5px;
}
.mgr10 {
    margin-right: 10px;
}
.mgr15 {
    margin-right: 15px;
}
.mgr12 {
    margin-right: 12px;
}
.mgr20 {
    margin-right: 20px;
}
.mgr25 {
    margin-right: 25px;
}
.mgr30 {
    margin-right: 30px;
}
.mgr35 {
    margin-right: 35px;
}
.mgr40 {
    margin-right: 40px;
}
.mgr45 {
    margin-right: 45px;
}
.mgr50 {
    margin-right: 50px;
}
.mgr55 {
    margin-right: 55px;
}
.mgr85 {
    margin-right: 85px;
}

//Line height
.line-height134 {
    line-height: 1.34;
}
.line-height167 {
    line-height: 1.67;
}

//Border-color
.border-bottom-1x {
    border-bottom: 1px solid;
}
.border-top-1x {
    border-top: 1px solid;
}
.border-right-1x {
    border-right: 1px solid;
}
.border-right-2x {
    border-right: 2px solid;
}
.border-right-0x {
    border-right: 0px;
}
.border-left-1x {
    border-left: 1px solid;
}
.border-left-0x {
    border-left: 0px;
}
.bd0 {
    border: 0px !important;
}
.bd1px {
    border: 1px solid;
}
.border-top-3x {
    border-top: 3px solid;
}
.bd2px {
    border: 2px solid;
}
.borderdotted-bootom-2x {
    border-bottom: 2px dotted;
}

.bd-color-black {
    border-color: #2f3439;
}
.bd-color-gray {
    border-color: #f0f0f0;
}
.bd-color-gray2 {
    border-color: #e9e9e9;
}
.bd-color-gray3 {
    border-color: #cacaca;
}
.bd-color-blue {
    border-color: #1890ff;
}
.bd-color-blue2 {
    border-color: #1687ee;
}
.bd-color-green {
    border-color: #7cb305 !important;
}
.bd-color-red {
    border-color: $txt-color-red;
}
.bd-color-white {
    border-color: #ffffff;
}

//Position
.position-re {
    position: relative;
}
.position-ab {
    position: absolute;
}
.position-fix {
    position: fixed;
}

.right24 {
    right: 24px;
}

//z-index
.zindex9 {
    z-index: 9;
}
.zindex99 {
    z-index: 99;
}
.zindex999 {
    z-index: 999;
}
.zindex9999 {
    z-index: 9999;
}

//Float
.fll {
    float: left;
}
.flr {
    float: right;
}
.clearboth {
    clear: both;
}

// Transformation
.txt-uppercase {
    text-transform: uppercase;
}
.txt-lowercase {
    text-transform: lowercase;
}
.txt-capitalize {
    text-transform: capitalize !important;
}
.txt-transform-none {
    text-transform: none !important;
}

//Decoration
.txt-underline {
    text-decoration: underline;
}
.txt-linethrough {
    text-decoration: line-through;
}
.txt-overline {
    text-decoration: overline;
}

// Alignment
.txt-left {
    text-align: left;
}
.txt-right {
    text-align: right !important;
}
.txt-center {
    text-align: center;
}
.txt-justify {
    text-align: justify;
}
.txt-nowrap {
    white-space: nowrap;
}
.txt-initial {
    white-space: initial;
}

//Display
.dpl-none {
    display: none;
}
.dpl-block {
    display: block;
}
.dpl-il-block {
    display: inline-block;
}
.dpl-flex {
    display: flex;
}
.dpl-none-mobile {
    display: block;
}
.dpl-none-pc {
    display: none;
}

.alignitemscenter {
    align-items: center;
}

.alignitemsend {
    align-items: flex-end;
}

//Flex
.justify-content-between {
    justify-content: space-between;
}
.justify-content {
    justify-content: center;
}
.justify-content-center {
    justify-content: center;
}
.justify-content-end {
    justify-content: flex-end;
}
.flex-wrap {
    flex-wrap: wrap;
}

//Overflow
.overflow-hidden {
    overflow: hidden;
}
.overflowy-hidden {
    overflow-y: hidden;
}
.overflow-auto {
    overflow: auto;
}

.wordbreakall {
    word-break: break-all;
}

.cursor-text {
    cursor: text;
}
.cursor-pointer {
    cursor: pointer;
}
.cursor-pointer {
    cursor: pointer;
}
.cursor-nodrop {
    cursor: no-drop;
}
.whitespace {
    white-space: nowrap;
}
.whitespace-initial {
    white-space: initial;
}

.opacity6 {
    opacity: 0.6;
}

//Border-radius
.border-radius2 {
    @include border-radius(2px);
}
.border-radius4 {
    @include border-radius(4px);
}
.border-radius6 {
    @include border-radius(6px);
}
.border-radius8 {
    @include border-radius(8px);
}
.border-radius12 {
    @include border-radius(12px);
}
.border-radius15 {
    @include border-radius(15px);
}

.border-radius-top-15 {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.border-radius-bottom-15 {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

//height
.height100 {
    height: 100% !important;
}
.height150 {
    height: 150px !important;
}
.minheight100 {
    min-height: 100%;
}

.tag--green {
    background-color: #7cb305;
    color: #ffffff;
    height: 24px;
    line-height: 24px;
    border-radius: 14px;
    padding: 0 16px;
}

.imgthumb32 {
    width: 32px;
    height: 32px;
    border: 1px solid #e9e9e9;
}
.imgthumb35 {
    width: 35px;
    height: 35px;
    border: 1px solid #e9e9e9;
}
.imgthumb40 {
    width: 40px;
    height: 40px;
    border: 1px solid #e9e9e9;
}

.imgthumb14 {
    width: 14px;
    height: 14px;
}
.imagefinishlogo {
    width: 200px;
    height: 146px;
}

.container-common {
    padding-left: 80px;
}
.dropzone > div:not(.dropped-file) {
    width: 80px !important;
    height: 80px !important;
}
.dropped-file {
    width: 80px;
    height: 80px;
}
.header {
    .container-common {
        margin-left: 140px;
    }
}

.header {
    .container-common {
        margin-left: 0px;
    }
}

//.menu-collapsed{
//	.container{
//		margin-left: 80px;
//	}
//}
.shadow-bottom {
    @include box-shadow(0, 3px, 5px, 0, rgba(0, 21, 41, 0.05));
}
.titletop {
    h2 {
        font-size: $txt-size-h1;
        color: $txt-color-black;
        font-weight: 400;
        text-transform: uppercase;
        span {
            font-size: 12px;
            color: $txt-color-blue;
            text-transform: capitalize;
        }
    }
}
.steps {
    .ant-steps-item-wait,
    .ant-steps-item-finish {
        .ant-steps-item-icon {
            background-color: transparent;
        }
    }
}

.ant-input-number {
    &.has-warning {
        border-color: $txt-color-orange;
        &:focus {
            box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
        }
    }
    &.has-error {
        border-color: $txt-color-red;
        &:focus {
            box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
        }
    }
}

.txt-error,
.ant-form-explain {
    color: $txt-color-red;
    font-size: $txt-size-h8;
    font-weight: 400;
}

.txt-warning {
    color: $txt-color-orange;
    font-size: $txt-size-h8;
    font-weight: 400;
}

.wpsearch {
    width: 330px;
    &__ic {
        right: 10px;
        top: 8px;
    }
}

textarea {
    resize: none;
}

.orderfixed {
    width: calc(100% - 105px);
    background-color: $bg-color-white;
    bottom: 0;
    height: 60px;
    line-height: 60px;
    &__left {
        width: 71.6333333%;
    }
    &__right {
        width: 28.266667%;
        background-color: #e8e8e8;
    }
}

//.menu-collapsed {
//	.orderfixed {
//		width: calc(100% - 105px);
//	}
//}
//
//.is-tablet{
//	.orderfixed{
//		width: calc(100% - 105px);
//	}
//}
.txtellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    height: 60px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
.showmore {
    border: 1px solid #e9e9e9;
    padding: 5px;
    border-top: 0;
    font-size: $txt-size-h7;
    color: $txt-color-blue;
    font-family: $robotofont;
    font-weight: 400;
}

.ant-timeline-item-content {
    top: -2px;
}
//.ant-timeline-item-tail{
//	top: -2px;
//}
.ant-timeline-item-last {
    .ant-timeline-item-content {
        top: 3px;
    }
}
.ant-timeline-item-left {
    .ant-timeline-item-content {
        margin-left: 40px !important;
    }
}

.ant-timeline-item-right {
    .ant-timeline-item-content {
        left: -50px !important;
    }
}

.ant-timeline-item-head {
    width: 16px;
    height: 16px;
    margin-left: -7px !important;
}
.ic__clockcircle {
    margin-left: 4px;
}

//Table
.tableresponsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

//table tbody tr td {
//	white-space: nowrap;
//}

.table__name {
    max-width: 300px;
    white-space: inherit !important;
}

.lbl150 {
    width: 150px;
}

//Notification
.notification__header {
    .ant-tabs-tab {
        font-size: $txt-size-h8;
    }
    .anticon-left {
        font-size: 16px;
        padding-top: 5px;
    }
}
.notification__content {
    &_list {
        .ant-list-item-meta-avatar {
            padding-top: 6px;
        }
        .ant-list-item-meta {
            flex: inherit;
            &-content {
                padding-right: 15px;
            }
        }
        background-color: #f0f2f5;
        &:hover {
            background-color: #fff;
        }
        &.active {
            background-color: #fff;
        }
    }
}

.ant-tabs-tabpane {
    &::-webkit-scrollbar-track {
        @include border-radius(20px);
        background-color: #ffffff;
    }

    &::-webkit-scrollbar {
        width: 6px;
        background-color: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
        @include border-radius(20px);
        background-color: $bg-color-gray2;
    }
}

//Timeline
.ant-steps-item-title {
    font-size: $txt-size-h7;
}
.ant-steps-item-description {
    font-size: $txt-size-h8;
}
//@media only screen and(max-width: 1024px){
//	//Step
//	.ticketdetailstep {
//		.ant-steps-item-content {
//			width: 100px;
//		}
//	}
//}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: #ffffff;
    border: 2px solid var(--ant-primary-color);
    width: 12px;
    height: 12px;
    top: -2px;
}

//Form
.ant-input-group {
    .ant-input-affix-wrapper {
        border-top-left-radius: 6px !important;
        border-bottom-left-radius: 6px !important;
    }
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    border-radius: 0 6px 6px 0;
    font-size: 16px;
}

.ant-input-search-button {
    padding: 0 10px;
}

.ant-input-group {
    .ant-input {
        @include border-radius(6px);
    }
}

//Alert
.alert-custom {
    .ant-alert {
        width: 100%;
        background-color: #fff;
        @include border-radius('6px');
        .ant-alert-content {
            .ant-alert-message {
                color: $txt-color-red;
                font-weight: 500;
                font-size: $txt-size-h8;
            }
            .ant-alert-description {
                color: $txt-color-red;
                font-size: $txt-size-h8;
            }
        }
    }
}
//Icon
.fa-edit {
    &:hover {
        color: $txt-color-blue;
    }
}

.text-right {
    text-align: right;
}
.d-none {
    display: none;
}
.justify-content-flex-end {
    justify-content: flex-end;
}
.text-underline {
    text-decoration: underline;
}
.upload-button {
    border: 1px dashed #ccc;
    display: flex;
    width: 80px;
    height: 80px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
}
.cart-foreign-amount-footer-item {
    &:after {
        content: '|';
        display: inline-flex;
        margin-left: 11px;
    }
    &.last {
        &:after {
            content: '';
        }
    }
}
.w100pc {
    width: 100%;
}
.zalo-chat-widget {
    left: calc(100% - 100px) !important;
}

.max-width-100pc img {
    max-width: 100%;
    height: auto;
}
