.tbg {
    &-input-search {
        width: 368px;
        border-right: none;
        border-radius: 0px;
    }

    &-btn-submit {
        background: #1890ff;
        border: none;
        padding: 4px 0;
        width: 34px;
        border-radius: 0px 4px 4px 0px;
        height: 34px;
        &.ant-btn:hover {
            background: #1890ff;
        }
        &.ant-btn:focus {
            background: #1890ff;
        }
        &.ant-btn:active {
            background: #1890ff;
        }

        &.ant-btn[disabled],
        .ant-btn[disabled]:hover,
        .ant-btn[disabled]:focus,
        .ant-btn[disabled]:active {
            border-color: #d9d9d9;
            background: #1890ff;
            text-shadow: none;
            box-shadow: none;
        }
    }

    &-select {
        &.ant-select-selector {
            border: none;
        }
        width: 130px;
        // border-radius: 4px 0px 0px 4px;
    }

    &-select-thirtParty {
        border: 1px solid #d9d9d9;
        // border-right: none;
        // margin-right: -1px;
        &:hover {
            border-color: #1890ff;
        }
        border-radius: 4px 0px 0px 4px;
    }

    &-select-popup {
        top: 140px !important;
        & .ant-dropdown-menu {
            border-radius: 16px;
            padding: 0;
            & .ant-dropdown-menu-item,
            .ant-dropdown-menu-submenu-title {
                padding: 8px 12px;
            }
            & .ant-dropdown-menu-item-divider {
                margin: 0;
            }
            & .ant-dropdown-menu-item:first-child {
                border-radius: 16px 16px 0 0;
            }
            & .ant-dropdown-menu-item:last-child {
                border-radius: 0 0 16px 16px;
            }
        }
    }
}
