.searchbox{
	&__label{
		width: 120px;
	}
	&__label2{
		min-width: 70px;
	}
	&__showmoresearch{
		border: 1px solid #E9E9E9;
		padding: 5px;
		border-top: 0;
	}
	.ant-tag-checkable-checked{
		color: $txt-color-white;
		box-shadow: 2px 2px 6px var(--ant-primary-color);
	}
	.ant-tag-checkable{
		margin-bottom: 8px;
	}

	.ant-checkbox-wrapper+.ant-checkbox-wrapper {
		margin-left: 0;
	}
}
.listorders{
	padding: 20px 25px;
	&__items{
		border: 1px solid #E8E8E8;
		@include border-radius(4px);
		&_right{
			&--status{
				color: $txt-color-white;
				font-size: 12px;
				font-family: $robotofont;
				font-weight: 400;
				@include border-radius(20px);
				text-transform: capitalize;
				height: 24px;
				line-height: 24px;
				padding: 0px 20px;
				border: 0;
				//min-width: 150px;
				text-align: center;
			}
			&--blue{
				background-color: #096DD9;
			}
			&--gray{
				background-color: #898989;
			}
			&--green{
				background-color: #7CB305;
			}
			&--red{
				background-color: #CF1322;
			}
		}
		&_code{
			padding-top: 2px;
		}
		//&_vertical{
		//	top: 8px;
		//}
	}
}

.wptracking{
	.ant-timeline{
		.ant-timeline-item-label {
			left: -20px;
			top: -3px;
			color: $txt-color-gray;
		}
		.ant-timeline-item-tail{
			border-left: 2px solid #DFE3F0;
		}
		.ant-timeline-item-content{
			top: -2px;
		}
		.ant-timeline-item-head-green{
			background: transparent;
			margin-left: -4px!important;
			margin-top: -15px;
		}
		.ant-timeline-item{
			padding-bottom: 40px;
			&:first-child{
				.ant-timeline-item-content{
					span{
						color: #0C9F47;
						font-weight: 600;
					}
				}
			}
		}
	}
}