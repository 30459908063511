html {
    display: flex;
    min-height: 100%;
    overflow-y: hidden;
}

body {
    margin: 0;
    min-height: 100%;
    width: 100%;
}

.anticon {
    vertical-align: middle;
}

.main-container {
    > div {
        min-height: 100%;
    }
}

.flex-none {
    flex: none;
}

.menu-has-footer {
    padding-bottom: 45px;

    .ant-layout-sider-children {
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 6px;
            background-color: var(--ant-primary-color);
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--ant-primary-color-active);
        }
    }

    .menu-footer {
        border-top: 1px solid #fff;
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 0;
    }
}

.btn-ghost-custom {
    &:hover {
        color: var(--ant-primary-color);
        background-color: #fff !important;
        border-color: #fff;
    }

    &:focus {
        color: var(--ant-primary-color);
        background-color: #fff !important;
        border-color: #fff;
    }
}

.scroll-wrapper {
    height: 100%;
    overflow: auto;
}

.login-wrapper {
    display: flex;
    min-height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-image: url('../images/img-bg.png');
    background-position: center;
    background-size: contain;

    .login-logo {
        max-height: 90px;
        max-width: 160px;
    }
}

.new-login-wrapper {
    display: flex;
    min-height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: contain;
}

.new-login-boxform {
    width: 70%;
    background-color: #d9d9d915;
    //min-height: 300px;
    //padding: 40px 60px;
    //box-sizing: border-box;
    //border-radius: 15px;
}

.new-login-boxform-bg {
    width: 100%;
    height: auto;
}

.new-login-boxform-logo {
    position: absolute;
    top: 10%;
    right: 0;
    left: 0;
    height: 70px;
    text-align: center;

    img {
        height: auto;
        max-height: 100%;
        width: auto;
        margin: 0 auto;
    }
}

.btn-new-register {
    font-size: 18px;
    font-weight: 300;
    background: var(--ant-primary-color) 0% 0% no-repeat padding-box;
    border-radius: 3px;
    color: #fff;
    display: inline-block;
    padding: 10px 30px;
    border: 1px solid var(--ant-primary-color);

    &.disabled {
        border-color: #d0d0d0;
        color: #d0d0d0;
        background-color: transparent;
    }
}

.new-login-boxform-title {
    text-transform: uppercase;
    color: #333333;
    font-size: 25px;
    padding-left: 20px;
    border-left: 3px solid #333333;
    line-height: 1;
    font-weight: bold;
}

.box-text-error {
    height: fit-content;
}

.input-new-register {
    input {
        //height: 50px;
        //border-radius: 25px;
        //padding-left: 60px;
    }

    .iconip {
        //position: absolute;
        //top: 5px;
        //left: 20px;
        //font-size: 20px;
    }

    .texterror {
        // position: absolute;
        // top: 55px;
        // display: block;
        // left: 60px;
        // font-size: 10px;
    }

    .ant-select {
        width: 100%;

        .ant-select-selector {
            display: flex;
            align-items: center;
            height: 42px;
            width: 100%;
            padding-left: 20px;

            .ant-select-selector__rendered {
                height: 42px;
                margin-left: 0;
            }

            .ant-select-selector-selected-value {
                height: 42px;
                line-height: 42px;
            }

            .ant-select-selection-search {
                .ant-select-selection-search-input {
                    height: 42px;
                }
            }
        }
    }

    .ant-select-search__field {
        padding-left: 0 !important;
    }

    &.errorip {
        input {
            border-color: #ac0014;
        }

        .ant-select-selection {
            border-color: #ac0014;
        }
    }
}

.new-login-boxform-left {
    width: 45%;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.new-login-boxform-right {
    width: 55%;
    padding: 30px;
}

.wrapperform {
    background-image: url('../images/bgform.png');
    justify-content: center;
    align-items: center;
}

.logo-image {
    height: 50px;
}

.login-content {
    border-radius: 12px;
    -webkit-box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    min-height: 300px;
    max-width: 600px;
}

.page403-content {
    -webkit-box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 460px;
}

.page403-container {
    width: 562px;
}

.login-image {
    z-index: 0;
    width: 50%;
}

.login-logo-image {
    max-height: 80px;
    max-width: 240px;
}

.custom-btn-large {
    &.ant-btn-lg {
        font-size: 14px;
    }
}

.vertical-align-middle {
    vertical-align: middle;
}

.width-100-percent {
    width: 100%;
}

.height-100-percent {
    height: 100%;
}

.min-width-150 {
    min-width: 150px;
}

.min-width-200 {
    min-width: 200px;
}

.min-width-250 {
    min-width: 250px;
}

.width-120 {
    width: 120px;
}

.width-150 {
    width: 150px;
}

.width-300 {
    width: 300px;
}

.max-width-400 {
    max-width: 400px;
}

.max-width-300 {
    max-width: 300px;
}

.max-width-250 {
    max-width: 250px;
}

.flex-1-1 {
    flex: 1 1;
}

.align-self-end {
    align-self: flex-end;
}

.text-center {
    text-align: center;
}

#root,
#app-main,
.main-body {
    //display: flex;
    min-height: 100%;
    width: 100%;
}

.splash-container {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.header-container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}

.align-items-start {
    align-items: start;
}

.align-items-end {
    align-items: flex-end;
}

.flex-direction-column {
    flex-direction: column;
}

.icon-error-input {
    position: absolute;
    left: -17px;
    top: 6px;
}

.break-word {
    word-break: break-word;
}

.whitespace-pre-wrap {
    white-space: pre-wrap;
}

.bold {
    font-weight: bold;
}

.normal {
    font-weight: normal;
}

.vertical-radio {
    display: block;
    height: 30px;
    lineheight: 30px;
}

.checkbox-group {
    &.vertical {
        .ant-checkbox-wrapper {
            display: block;
            margin-left: 0;
            margin-bottom: 10px;
        }
    }
}

.box-checkbox-custom {
    .ant-checkbox-wrapper {
        display: block;
        margin-left: 0;
        margin-bottom: 10px;
    }
}

.line-height-1 {
    line-height: 1;
}

.order-row-fixed {
    bottom: 0;
    height: 100px;
    display: flex;
    align-items: center;
    background: #fff;
}

.white-space-pre-wrap {
    white-space: pre-wrap;
}

.box-chat-wrapper {
    display: flex;

    > .ant-spin-container {
        width: 100%;
    }
}

.chat-attachments-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 10px;
}

.chat-attachments-item {
    display: inline-flex;
    width: 60px;
    height: 60px;

    img {
        width: 100%;
    }
}

.modal-preview-image {
    .ant-modal-close-x {
        width: 26px;
        height: 30px;
        line-height: 30px;
    }
}

.custom-step {
    &.ant-steps-horizontal {
        .ant-steps-item-content {
            width: 105px;
        }

        .ant-steps-item-tail {
            margin: 0 0 0 52.5px;
            top: 9px;
            &::after {
                height: 1px;
            }
        }

        .ant-steps-item-icon {
            margin-left: 50px;
        }

        .ant-steps-item-title {
            font-size: 12px;
            padding-right: 0px;
        }

        .ant-steps-item-description {
            font-size: 10px;
        }
    }

    .ant-steps-item-title {
        font-weight: bold;
    }

    &.ant-steps-small .ant-steps-item-container .ant-steps-item-icon {
        width: 10px;
        height: 10px;
        margin-top: 5px;
    }
}

.status-dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: inline-block;
    background: var(--ant-primary-color-active);
    margin-right: 5px;
}

.custom-empty {
    .ant-table-placeholder {
        display: none;
    }
}

.btn-recharge {
    background: #fff8f7;
    border: 1px solid #f5222d;
    height: 40px;
    line-height: 32px;
    color: #f5222d;
    font-size: 14px;
    font-weight: 400;

    &:hover {
        border: 1px solid #f5222d;
        color: #fff;
        background: #f5222d;
    }

    &:focus {
        border: 1px solid #f5222d;
        color: #fff;
        background: #f5222d;
    }
}

.border-1px-solid {
    border: solid 1px #e0e0e0;
}

.border-bottom-1px-solid-gray {
    border-bottom: solid 1px #e0e0e0;
}

.txt-color-gray2 {
    color: #c1c1c1;
}

.vertical-align-text-top {
    vertical-align: text-top;
}

.notification-popover {
    width: 400px;

    .ant-popover-inner-content {
        padding: 0;
    }

    .ant-tabs-nav .ant-tabs-tab {
        margin: 0;
    }

    .ant-tabs-bar {
        margin: 0;
    }

    .ant-tabs-tabpane {
        height: 350px;
        overflow: auto;
    }
}

.table-settings-notification {
    thead th {
        background-color: #fff !important;
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
        padding: 24px 16px;
    }

    tr td:first-child,
    tr th:first-child {
        padding-left: 0;
    }

    .ant-table-tbody tr:last-child td {
        border-bottom: none;
    }
    tr.ant-table-expanded-row > td {
        background: #fff;
    }
}

.notification-collaspe {
    .ant-collapse-content-box {
        padding: 0;
    }
}

.notification-collaspe-p-0 {
    .ant-collapse-header {
        padding: 0 !important;
    }
}

.image-preview {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
}

.txt-through {
    text-decoration: line-through;
}

.notification-item {
    display: block;
    border-bottom: 1px solid #e8e8e8;

    &:last-child {
        border-bottom: none;
    }
}

.no-white-space {
    table tbody tr td {
        white-space: unset;
    }
}

.table-white-space {
    .ant-table-thead > tr > th {
        white-space: nowrap;
        padding: 10px;
    }
}

.modal-user-banned {
    .ant-modal-body {
        padding: 15px;
    }
}

.btn-ghost-custom {
    color: var(--ant-primary-color);
    background-color: #fff !important;
    border-color: #fff;

    &:hover {
        color: #fff;
        background-color: transparent !important;
        border-color: #fff;
    }

    &:focus {
        color: #fff;
        background-color: transparent !important;
        border-color: #fff;
    }
}

//.ant-menu-submenu .ant-menu-submenu-arrow{
//  height: 10px;
//}

.custom-modal-confirm {
    .ant-modal-body {
        padding: 15px;
    }

    .ant-modal-confirm-title {
        font-weight: bold;
        text-transform: uppercase;
    }
}

.custom-alert {
    border-radius: 0;
}

.popup-profile {
    .ant-popover-inner-content {
        padding: 0;
    }

    .ant-popover-arrow {
        display: none;
    }
    .ant-popover-inner {
        border-radius: 16px;
    }
}

.popover-profile-content {
    .popover-profile-content-item {
        &:hover {
            color: #000;
        }
    }
}

.ant-empty {
    padding-top: 20px;
    padding-bottom: 20px;

    .ant-empty-image img {
        margin: auto;
    }
}

.ant-input {
    &textarea {
        line-height: 1.6;
    }
}

.comment-item {
    padding: 10px;
    border-radius: 10px;
    background: #fffce0;

    &.customer {
        background: #e6f7ff;
    }
}

.customer-level-box {
    display: flex;
    justify-content: center;
    background: url('../images/bg.png') center center #2e9aff;
    padding: 10px 20px;
    position: relative;

    .btn-show-discount {
        position: absolute;
        bottom: 10px;
        right: 20px;
        text-decoration: underline;
    }

    .customer-level-progress-box {
        width: 50%;

        .ant-progress-inner {
            background: #0983c6;
        }
    }

    .customer-current-level-text {
        font-weight: bold;
    }

    .customer-next-level {
        margin-left: 10px;
        text-align: center;
        color: #fff;

        img {
            max-height: 100px;
            max-width: 100px;
        }
    }

    .customer-current-level {
        margin-right: 10px;
        text-align: center;
        color: #fff;

        img {
            max-height: 100px;
            max-width: 100px;
        }
    }
}

.customer-table-head {
    padding: 5px 10px;
    height: 33px;
}

.customer-table-row {
    padding: 5px 10px;
    height: 33px;
}

.modal-no-header {
    .ant-modal-header {
        border: none;
        padding-bottom: 0;
    }
}

.custom-icon-shipment {
    padding: 5px;
    border: 1px solid #898989;
    border-radius: 4px;
}

.custom-icon-shipment-big {
    padding: 10px;
    border: 2px solid #898989;
    border-radius: 8px;
}

//.custom-table-expand-row {
//  .ant-table-expanded-row {
//    >td {
//      &:first-child{
//        display: none;
//      }
//    }
//  }
//}

.custom-table {
    th {
        &.text-center {
            text-align: center !important;
        }
    }
}

.white-tool-tip {
    .ant-tooltip-inner {
        background-color: white;
    }

    .ant-tooltip-arrow::before {
        display: none;
    }
}

.hidden-expand-row {
    .ant-table-tbody {
        .ant-table-row:first-child {
            .ant-table-row-expand-icon-cell {
                i {
                    display: none;
                }
            }
        }
    }
}

.menu-footer-item {
    &:after {
        content: '|';
        padding-left: 10px;
        padding-right: 10px;
        color: #fff;
    }

    &:last-child {
        &:after {
            display: none;
        }
    }
}

.modal-article {
    width: 800px !important;
    max-width: 90% !important;

    .ant-modal-title {
        color: #f5222d;
    }

    .ant-modal-header {
        border: none;
        padding: 15px;
    }

    .ant-modal-body {
        padding: 15px;
        padding-top: 0;
    }

    .ant-modal-footer {
        display: none;
        border: none;

        .ant-btn {
            &:first-child {
                display: none;
            }

            &:last-child {
                padding-right: 40px;
                padding-left: 40px;
            }
        }
    }

    .ant-modal-close-x {
        width: 40px;
        height: 40px;
        line-height: 40px;
    }
}

.carousel-article {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 4px;

    .item-carousel-article {
        display: none;

        &.active {
            display: block;
        }
    }

    .carousel-article-control-bar {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        .carousel-article-control-bar-item {
            margin-left: 2px;
            margin-right: 2px;
            background-color: #d6d6d6;
            width: 20px;
            height: 4px;

            &.active {
                background-color: var(--ant-primary-color);
            }
        }
    }
}

.modal-import {
    .servicesbox {
        padding-top: 10px;
    }

    .sidebar__title {
        padding-left: 0;
    }

    .servicesbox__content {
        padding-left: 0;
        padding-right: 0;
    }

    .ant-modal-footer {
        padding-left: 24px;
        padding-right: 24px;

        .ant-btn-primary {
            position: relative;
            padding-left: 30px !important;
            padding-right: 30px !important;

            span {
                margin-left: 0 !important;
            }

            .anticon-loading {
                position: absolute;
                left: 10px;
                top: 8px;
                margin-left: 0 !important;
            }
        }
    }
}

.table-refund {
    th {
        vertical-align: middle;
    }
}

.table-image-cell {
    width: 24px;
}

.shake-hard {
    animation-name: shake-hard;
    animation-duration: 100ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-play-state: running;
}

thead tr:first-child .linedg {
    position: relative;

    > span {
        width: 100%;
    }

    &:before {
        content: '';
        width: 185px;
        height: 47px;
        border-bottom: 1px solid #eae7e7;
        -webkit-transform: translateY(-34px) translateX(129px) rotate(17deg);
        position: absolute;
        right: 200px;
    }
}

.timeline-tooltip {
    .ant-tooltip-inner {
        padding: 15px;
    }

    .ant-timeline-item-tail {
        left: 0;
    }

    .ant-timeline-item-content {
        min-height: auto;
    }

    .ant-timeline-item-last {
        padding: 0;

        .ant-timeline-item-content {
            top: -2px;
        }
    }

    &--milestone-peer-payment-list {
        .ic__clockcircle {
            margin-left: 1px;
            top: -4px;
            position: relative;
            font-size: 18px;
        }

        .ant-timeline-item-content {
            top: -5px !important;
        }
    }
}

.button-back-print {
    cursor: pointer;
    padding: 20px 10px;
}

.btn-print {
    position: fixed;
    background-color: #2e9aff;
    font-size: 40px;
    right: 80px;
    top: 40%;
    padding: 20px;
    height: auto;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;

    &:hover {
        background-color: transparent;
        color: #000;
        border: 1px solid #000;
    }
}

.zalo-page-container {
    border-left: 2px solid #2e9aff;

    .zalo-page-step {
        margin-left: -35px;

        .icon-step {
            display: flex;
            //justify-content: center;
            align-items: center;

            .zalo-step-dot {
                width: 30px;
                height: 30px;
                background-color: #2e9aff;
                border: 5px solid #fff;
                border-radius: 50%;
            }

            .zalo-step-line {
                width: 150px;
                height: 2px;
                background-color: #2e9aff;
                margin-left: -5px;
            }

            .zalo-step-num {
                width: 50px;
                height: 50px;
                background-color: #2e9aff;
                border-radius: 50%;
                text-align: center;
                line-height: 50px;
                font-size: 30px;
                color: #fff;
            }
        }
    }
}

.min-width-page {
    min-width: 1200px;
}

.table-expand-row {
    .ant-table-expanded-row {
        //td:first-child {
        //  display: none;
        //}
    }
}

.line-through {
    text-decoration: line-through;
}

.line-underline {
    text-decoration: underline;
}

.txt-color-yellow {
    color: #ff8a33;
}

.footer-item-head {
    color: var(--ant-primary-color);
    text-transform: uppercase;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: solid 1px var(--ant-primary-color);
    text-align: left;
    font-weight: bold;
}

.orderfixed {
    position: fixed;
}

.modal-voucher {
    .ant-modal-header,
    .ant-modal-footer {
        display: none;
    }

    .ant-modal-close {
        left: -30px;
        top: 15px;
        color: #fff;

        i {
            font-size: 30px;
        }
    }

    .ant-modal-content {
        background: transparent;
        box-shadow: none;
    }

    .bg-svg {
        width: 570px;

        svg {
            height: 300px;
        }
    }

    .voucher-input-container {
        position: absolute;
        width: 45%;
        left: 57px;
        top: 53%;
    }

    .voucher-pin-container {
        position: absolute;
        width: 46%;
        left: 57px;
        top: 63%;
    }

    .voucher-input {
        padding-right: 35px;
    }

    .voucher-mess {
        position: absolute;
        top: 37px;
        font-size: 14px;
        line-height: 1.2;
    }

    .voucher-alert {
        position: absolute;
        bottom: 37px;
        font-size: 14px;
        line-height: 1.2;
    }

    .btn-check-voucher {
        position: absolute;
        width: 33px;
        height: 24px;
        top: 4px;
        right: 0;
        border-left: 1px solid #ddd;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .voucher-btn-container {
        position: absolute;
        width: 40%;
        left: 110px;
        bottom: 5%;
    }

    .btn-accept-voucher {
        padding: 6px 16px;
        background: #f6f6f6;
        color: #b1b1b1;
        border-radius: 4px;

        &.active {
            background: var(--ant-primary-color);
            color: #fff;
        }

        &.disabled {
            opacity: 0.6;
        }
    }
}

// .ant-divider-vertical {
//     background: var(--ant-primary-color) !important;
// }

.bg-gradient-purple {
    background-image: linear-gradient(to right, #b37feb, #d2b8ee);
}

.bg-gradient-green4 {
    background-image: linear-gradient(to right, #61dcd4, #a5e5e1);
}

.bg-gradient-blue {
    background-image: linear-gradient(to right, var(--ant-primary-color), var(--ant-primary-color-hover));
}

.dashboard-box {
    box-shadow: 15px 15px 20px #0000000d;
}

.dashboard-box-2 {
    box-shadow: 10px 10px 20px #0000000d;
}

.dashboard-comment-item {
    background-color: #e6f7ff;

    &:hover {
        background-image: linear-gradient(to right, #e6f7ff, #e8e8e8);
    }
}

.dashboard-weight-box {
    border-radius: 30px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 190px;
    box-shadow: 5px 5px 20px #00000029;
    height: 100%;
}

.overlay-item-preview {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
}

.chat-attachments-item {
    &:hover {
        .overlay-item-preview {
            display: flex;
        }
    }
}

.box-shadow-blue {
    //box-shadow: 2px 2px 6px #096DD9CC;
}

.btn-peer-payments {
    background: #f5f0e180;
    color: #8c8c8c;
    text-transform: uppercase;
    font-weight: 300;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    cursor: pointer;

    &.active {
        background: var(--ant-primary-color-active);
        color: #fff;
        cursor: default;
    }
}

.btn-add-peer-payment {
    border: 1px solid var(--ant-primary-color-active);
    border-radius: 5px;
    cursor: pointer;
    color: var(--ant-primary-color-active);
    padding: 5px 10px;

    &.btn-disabled {
        color: rgba(0, 0, 0, 0.25);
        border-color: #d9d9d9;
        background: #f5f5f5;
        text-shadow: none;
        box-shadow: none;
        cursor: not-allowed;
    }
}

.btn-payment-detail {
    &.btn-disabled {
        color: rgba(0, 0, 0, 0.25);
        border-color: #d9d9d9;
        background: #f5f5f5;
        text-shadow: none;
        box-shadow: none;
        cursor: not-allowed;
    }
}

.btn-modal-markup-rate-groups {
    border: 1px solid #389e0d;
    border-radius: 5px;
    cursor: pointer;
    color: #389e0d;
    padding: 5px 10px;
}

.peer-payment-nav {
    width: 30px;
    height: 30px;
    background: #262626c6;
    color: #fff;
    border-radius: 50%;
    font-size: 18px;
    font-weight: bold;
    justify-content: center;
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 999999999;
    cursor: pointer;
    top: 33%;

    &.left {
        left: -10px;
    }

    &.right {
        right: -10px;
    }
}

.border-top-radius {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.border-bottom-radius {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.exchanged-amount-box {
    background: #f5f0e1;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
}

.exchanged-amount-box-value {
    color: #fec03b;
    font-size: 29px;
}

.footer-bottom {
    background: #f0f2f5;
    color: #868484;
}

.btn-package-damage-log {
    padding: 3px 15px;
    border: solid 1px var(--ant-primary-color);
    color: var(--ant-primary-color);
    cursor: pointer;
    display: inline-block;
    border-radius: 6px;
}

.modal-log-package-damage {
    .ant-modal-header {
        border-bottom: none;
        padding: 15px 20px;
    }

    .ant-modal-body {
        padding: 0;
    }
}

.package-damage-log-item {
    background: #d9d9d931;
    padding: 20px;
}

.table-mini {
    th,
    tr,
    td {
        padding: 5px !important;
    }
}

.blink_me {
    animation: blinker 1.5s linear infinite;
}

.table-hardcode {
    tbody {
        tr:nth-child(1),
        tr:nth-child(2),
        tr:nth-child(3),
        tr:nth-child(4) {
            background-color: #a1c682;
        }

        tr:nth-child(5),
        tr:nth-child(6),
        tr:nth-child(7) {
            background-color: #82ddcd;
        }
    }
}

.table-hidden-header {
    thead {
        display: none;
    }

    th,
    tr,
    td {
        padding: 10px 10px 10px 0 !important;
    }
}

.box-fee-peerpayment {
    background: #e0e0e03e;
    border-radius: 5px 5px 0px 0px;
    padding: 15px;
}

.box-fee-peerpayment-total {
    background: #cccaca4d;
}

.modal-peer-payment {
    .ant-modal-header {
        border: unset;
        padding: 24px;
    }

    .ant-modal-body {
        padding-top: 0px;
    }

    .box-confirm-peerpayment {
        .payment-info-title {
            color: #1a1a1a;
            font-size: 12px;
        }

        .payment-info-detail {
            margin: 0.75rem 0;
            background-color: #ddeeff;
            border-radius: 16px;
            padding: 16px;
        }

        .ant-alert {
            border-radius: 16px;
        }

        .better-offer-info {
            border-left: 1px solid #ebebeb;
        }
    }
}

.popup-confirm-place-order {
    .confirm-buy-better-offer {
        background: none !important;
        color: #5a5a5a !important;

        &:hover {
            color: #1890ff !important;
        }
    }

    .cancel-buy-better-offer {
        background: none !important;
        color: #5a5a5a !important;

        &:hover {
            color: #ff4559 !important;
        }
    }

    .ant-popover-inner {
        border-radius: 4px;
    }
}

.text-shadow-none {
    text-shadow: none !important;
}

.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
    .payment-info-detail {
        margin: 0.75rem 0;
        background-color: #ddeeff;
        border-radius: 16px;
        padding: 16px;
    }

    .ant-alert {
        border-radius: 8px;
    }

    .better-offer-info {
        border-left: 1px solid #ebebeb;
    }
}

.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
    overflow-x: hidden;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
    background: var(--ant-primary-color-active);
}

.ant-collapse-header {
    display: flex;
    align-items: center;
}

.ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
}

.ant-tabs-tab {
    padding-left: 15px;
    padding-right: 15px;
}

.ant-list-item-meta-content {
    width: 100%;
}

.ant-checkbox-checked:after {
    // top: -2px;
    width: 16px;
    height: 16px;
}

.ant-checkbox-inner {
    display: inline-block !important;
}

@keyframes blinker {
    50% {
        opacity: 0.1;
    }
}

@media print {
    .button-back-print {
        visibility: hidden;
    }

    .btn-print {
        visibility: hidden;
    }

    .sheet {
        page-break-after: always;
    }
}

@media (max-width: 1200px) {
    .orderfixed {
        position: relative;
        //display: block!important;
        width: 100% !important;
    }
}

.footer-customer {
    img {
        max-width: 100%;
    }
}

.merchantUrl {
    width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.image-center {
    margin: 0 auto;
}

.ant-badge {
    // margin-left: 6px;

    .ant-badge-count {
        background-color: #1890ff;
    }
}

.popover-search-order-taobao.ant-popover {
    left: 293px !important;

    .ant-popover-arrow {
        z-index: unset;
    }

    .ant-popover-inner {
        width: 300px;
        border-radius: 12px;
        padding: 24px;

        .ant-popover-inner-content {
            padding: 0;
            width: 100%;
        }
    }
}

.custom-pagination {
    .ant-select {
        &-selector {
            border-radius: 8px !important;
        }
    }
}

.input-money {
    position: relative;
    .ant-input {
        height: auto;
        border-radius: 8px;
        padding: 5px 36px 5px 16px;
        min-width: 200px;
    }
    .ant-input-number-input {
        padding: 0;
    }
    .input-money_icon {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translate(-8px, -50%);
    }
}

.group-radio-button {
    border-radius: 6px;
    border: 1px solid #ebebeb;
    padding: 10px 18px !important;
    &.active {
        border-color: #1890ff;
    }
    &:hover {
        border-color: #1890ff;
    }
}

.modal-success-custom {
    width: 550px !important;
    .ant-modal-body {
        padding: 24px !important;
    }
    .ant-modal-confirm-btns {
        .ant-btn-primary {
            text-transform: capitalize;
        }
    }
}
.rangeExchangeItem {
    margin-bottom: 12px;
    border-bottom: 1px solid #ebebeb;
}

.rangeExchangeItem:last-child {
    border-bottom: unset;
}

.tooltip-common {
    .ant-tooltip-inner {
        padding: 12px;
        border-radius: 6px;
    }
}

.btn-spinning {
    .ant-spin-dot {
        position: absolute;
        top: 35% !important;
        left: 50%;
        margin: -10px;
    }
}

.modal-order-success {
    .ant-modal-content {
        .ant-modal-header {
            border-bottom: none;
            padding-bottom: 0;

            .ant-modal-title {
                text-align: center;
            }
        }

        .ant-modal-body {
            padding-top: 12px;
        }

        .ant-modal-footer {
            display: none !important;
        }
    }
}

.custom-line-dash {
    height: 1px;
    background: linear-gradient(to right, #ebebeb 12px, transparent 8px);
    background-size: 22px;
}

.custom-line-dash-2 {
    height: 1px;
    background: linear-gradient(to right, #c0c0c0 12px, transparent 8px);
    background-size: 16px;
}

.popver-custom {
    line-height: initial;
}

.drawer-order-product {
    .ant-drawer-body {
        padding-top: 0;
    }
    .ant-drawer-header {
        border-bottom: unset;
    }
}

.border-b-order-product {
    border-bottom: 1px dashed #c0c0c0;
    margin: 16px 0;
}

._txt-comment-item {
    img {
        width: 100%;
    }
}

//@media (min-width: 1025px) {
//  .hidden-xl {
//    display: none;
//  }
//}
//
//@media (max-width: 1024px) {
//  .hidden-md {
//    display: none;
//  }
//}

@import './helpers/mixins';

@import './helpers/variables';
@import './module/common';

@import './module/datepicker';
@import './module/input';
@import './module/pagination';
@import './module/button-v2';
@import './module/input';
@import './antd/pagination';

@import './pages/RequestWithDrawal';

@import './antd/table';
@import './antd/select';
@import './antd/tab.scss';
@import './antd/tooltip.scss';
@import './antd/dropdown';

@import './module/button';

@import './module/table';

@import './module/sidebar';

@import './module/form';

@import './module/boxchat';

@import './module/modal';

@import './layout/layout-header';

@import './layout/layout-nav';

@import './layout/layout-order';

@import './layout/layout_carts';

@import './layout/layout-orderdetail';

@import './layout/layout-orderdetail';

@import './layout/layout-profile';

@import './layout/layout-createticket';

@import './layout/layout-ticketdetail';

@import './layout/layout-form';

@import './pages/addressChina';

@import './pages/addressChina';

@import './pages/TaobaoGlobal/step1';

@import './module/popover';

@import './module/steps';
@import './module/quill';
