$color-primary-btn: var(--ant-primary-color);
$color-text-btn: #ffffff;
$color-primary-active-btn: var(--ant-primary-color-active);
$shadow-primary-btn: 0px 0px 0px 2px #e7effe !important;

$color-cancel-btn: #ffffff;
$color-text-cancel-btn: #333333;
$shadow-cancel-btn: 0px 0px 0px 2px #f1f1f1 !important;
$color-text-cancel-btn-disable: #b1b1b1;
$color-cancel-active-btn-disable: #111111;
$color-cancel-btn-disable: #f6f6f6;

$color-default-btn: #ffffff;
$color-text-default-btn: var(--ant-primary-color);
$shadow-default-btn: 0px 0px 0px 2px #def !important;
$color-text-default-btn-disable: #b1b1b1;
$color-default-active-btn: var(--ant-primary-color-active);
$color-default-btn-disable: #f6f6f6;

$color-danger-btn: #ff4559;

.v2-layout {
    .ant-btn {
        padding: 5px 16px;
        text-transform: capitalize;
        text-shadow: none;
        height: auto;
        @media (max-width: 1024px) {
            height: 40px;

            &.ant-btn-link {
                height: auto;
            }
        }

        &[ant-click-animating-without-extra-node='true']::after {
            display: none;
        }

        &-primary {
            background: $color-primary-btn;
            border-color: $color-primary-btn;
            border-radius: 4px;
            color: $color-text-btn;
            transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);

            &:hover {
                box-shadow: $shadow-primary-btn;
                background-color: #41a4ff;
            }

            &:active {
                background: $color-primary-active-btn;
            }

            &[disabled] {
                background: #f6f6f6;
                border: 1px solid #ebebeb;
                color: #b1b1b1;

                &:hover {
                    box-shadow: none !important;
                }
            }

            &.ordertop__search_tag-list {
                &:hover {
                    box-shadow: none !important;
                }
            }
        }

        &-default {
            background: $color-default-btn;
            border-radius: 4px;
            color: $color-text-default-btn;
            border: 1px solid $color-text-default-btn;
            transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);

            &:hover {
                box-shadow: $shadow-default-btn;
                color: $color-default-btn;
                background: $color-text-default-btn;
            }

            &:active {
                color: $color-default-btn;
                background: $color-default-active-btn;
                box-shadow: none !important;
            }

            &[disabled] {
                background: $color-default-btn-disable;
                color: $color-text-default-btn-disable;
                border: 1px solid #ebebeb;
            }
        }

        &-link {
            color: $color-primary-btn;

            &[disabled] {
                background: $color-default-btn-disable;
                color: $color-text-default-btn-disable;
                border: 1px solid #ebebeb;
            }
        }
        &-dangerous {
            background: $color-cancel-btn;
            border-radius: 4px;
            color: $color-text-cancel-btn;
            border: 1px solid #c0c0c0;
            transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);

            &:hover {
                box-shadow: none !important;
                color: $color-danger-btn;
                background: $color-cancel-btn;
                border-color: $color-danger-btn;
            }

            &:active {
                color: $color-danger-btn;
                background: $color-cancel-btn;
                box-shadow: none !important;
                border-color: $color-danger-btn;
            }

            &[disabled] {
                background: $color-default-btn-disable;
                color: $color-text-default-btn-disable;
                border: 1px solid #ebebeb;
            }
        }
    }

    .btn-cancel {
        background: $color-cancel-btn;
        border-radius: 4px;
        color: $color-text-cancel-btn;
        border: 1px solid #c0c0c0;
        transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);

        &:hover {
            box-shadow: $shadow-cancel-btn;
            background: #fff;
            color: #1a1a1a;
        }

        &:active {
            color: $color-cancel-btn;
            background: $color-cancel-active-btn-disable;
            box-shadow: none !important;
        }

        &[disabled] {
            background: $color-cancel-btn-disable;
            color: $color-text-cancel-btn-disable;
            border: 1px solid #ebebeb;
        }
    }

    .btn-confirm-cancel {
        background: #ff4559;
        border-radius: 4px;
        color: $color-default-btn;
        border: 1px solid #ff4559;
        transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);

        &:hover {
            box-shadow: $shadow-cancel-btn;
            background: #fe4f61;
            color: $color-default-btn;
        }

        &:active {
            color: $color-default-btn;
            background: #d33f4d;
            box-shadow: none !important;
        }

        &[disabled] {
            background: $color-cancel-btn-disable;
            color: $color-text-cancel-btn-disable;
            border: 1px solid #ebebeb;
        }
    }

    .ant-checkbox {
        &-wrapper {
        }

        &-inner {
            // background: #ffffff;
            border: 1px solid #c0c0c0;
            border-radius: 3px;

            &:hover {
                background: $color-text-default-btn;
            }
        }

        &-checked {
            &::after {
                border-radius: 3px;
                border-color: $color-text-default-btn;
            }
        }
    }
}
