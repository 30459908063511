$border-input-color: #c0c0c0;
$blue-violet: #0d6bc1;
$radius-8px: 8px;
$border-input-color: #1890ff33;
$font-weight: 500;
$secondary-color: var(--ant-primary-color);
$txt-color-white: #ffffff;
$bg-color-dark: #011d57;
$gray-color: #c0c0c0;

.title-input {
    color: #1a1a1a;
}

.status--btn {
    background-color: #F6F6F6 !important;
    color: #5A5A5A !important;

    &.activeBtn{
        background-color: $secondary-color !important;
        color: $txt-color-white !important;
    }
}

.drawal-quantity{
    background-color: $secondary-color !important;
    color: $txt-color-white !important;
}

.text-ellipse-requestWithDrawal {
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
    width: 310px;
}


// .ant-btn-default {
//     box-shadow: none;

//     border-radius: 4px;
//     border-color: $secondary-color;
//     color: $secondary-color;

//     &:hover {
//         color: $txt-color-white;
//         border-color: $secondary-color;
//         background-color: $secondary-color;
//     }

//     &:focus {
//         border-color: $secondary-color;
//         color: $txt-color-white;
//         background-color: $bg-color-dark;
//     }
// }

// .ant-btn-primary {
//     border-radius: 4px;
//     color: $txt-color-white;
//     background-color: $secondary-color;
//     border-color: $secondary-color;

//     &:hover, &:focus {
//         border-color: $secondary-color;
//         color: $txt-color-white;
//         background-color: $secondary-color;
//         box-shadow: 0px 0px 0px 2px #e7effe;
//     }
// }

.select-input__common {
    .ant-select-selector {
        border-radius: 4px !important;
        border: 1px solid $border-input-color !important;
    }
}

.histories {
    .ant-timeline-item {
        .ant-timeline-item-tail {
            top: 20px;
            left: 5px;
            bottom: 20%;
            height: 70%;
            border-left: 1px solid $blue-violet;
        }
        &.ant-timeline-item-last {
            padding-bottom: 0;
        }
    }

    .ant-timeline-item-head-blue {
        border-color: $blue-violet;
    }

    .ant-timeline-item-head {
        // position: relative;
        border: 1px solid $blue-violet;
        // width: 11px;
        // height: 11px;
        // background-color: $blue-violet;
        &:after {
            width: 4px;
            height: 4px;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            display: block;
            background-color: $blue-violet;
            border-radius: 50%;
            content: " ";
        }

        // padding: 1px;
    }

    .ant-timeline-item-content {
        top: -3px;
        margin: 0 0 0 20px;
    }
}

.request-draw-table {
    .ant-table {
        tbody {
            .ant-table-row {
                &.expand-parent {
                    // overflow: hidden;
                    td {
                        &:first-child {
                            border-bottom-left-radius: 0;
                        }

                        &:last-child {
                            border-bottom-right-radius: 0;
                        }
                        > div {
                            background-color: #ccdeff !important;
                        }
                    }
                }

                td {
                    border-bottom: none;
                }
            }

            .ant-table-expanded-row {
                position: relative;

                td {
                    padding: 0px;
                    background-color: #eff5ff !important;
                    border-bottom-right-radius: 8px;
                    border-bottom-left-radius: 8px;

                    .expand-row-container {
                        padding: 12px 36px;
                        max-width: 100%;
                    }
                }
            }

            // td.ant-table-cell-row-hover {
            //     background-color: #EFF5FF;
            // }
        }

        .ant-table-container {
            .ant-table-content table {
                border-collapse: separate;
                border-spacing: 0 !important;
                // margin-top: -12px;
            }
            .ant-table-measure-row {
                display: none;
            }
        }

        .ant-table-tbody > tr > td {
            border-bottom: none;
        }

        .ant-table-thead tr th:nth-child(1) {
            border-top-left-radius: 6px;
            padding-left: 12px;
        }

        .ant-table-thead tr th:nth-child(3) {
            padding-right: 40px;
        }

        .ant-table-thead tr th:nth-child(6) {
            padding-right: 26px;
        }

        .ant-table-thead tr th:last-child {
            border-top-right-radius: 6px;
        }

        .ant-table-thead > tr > th {
            background-color: #f6f6f6;
            border-bottom: none;
            padding: 5px 12px;
            margin-bottom: 12px;

            ::before {
                content: none;
            }
        }

        .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
            content: none;
        }

        .ant-table-tbody > tr > td:nth-child(2) {
            padding-left: 0;
        }
    }
    .tr-even {
        td {
            background-color: #ffffff;
            > div {
                background-color: #f6f6f6;
            }
        }
    }
    .ant-table-tbody {
        .ant-table-row {
            .ant-table-cell {
                // box-sizing: content-box;
                height: 68px;
                padding: 0;
                padding-top: 12px;
                background: #ffffff;
                > div {
                    height: 100%;
                    padding: 18px 12px;
                    @supports (-webkit-overflow-scrolling: touch) {
                        box-sizing: content-box;
                    }
                }

                &-row-hover {
                    > div {
                        background: #eff5ff !important;
                    }
                }
            }

            .ant-table-cell:nth-child(3) {
                > div {
                    padding-right: 40px;
                }
            }
        }
    }
    ._row-order {
        td:first-child {
            > div {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }
        }
        td:last-child {
            > div {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }
        }
        &.expand-parent {
            td:first-child {
                > div {
                    border-bottom-left-radius: 0;
                }
            }
            td:last-child {
                > div {
                    border-bottom-right-radius: 0;
                }
            }
        }
    }
}

// date picker
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: $secondary-color;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $secondary-color;
}
.ant-picker-panel .ant-picker-footer {
    color: $secondary-color;
}

.ant-select-dropdown {
    padding: 0;
    border-radius: 6px;

    .rc-virtual-list {
        .rc-virtual-list-holder {
            .rc-virtual-list-holder-inner {
                .ant-select-item:not(:last-child) {
                    border-bottom: 1px solid #f1f1f1;
                }

                .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
                    background-color: #eff5ff;
                    color: #1a1a1a;
                    font-size: 14px;
                    font-weight: $font-weight;
                }
            }
        }
    }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: $font-weight;
    color: #1a1a1a;
    background-color: #ccdeff;
}

//pc
@media only screen and (min-width: 1024px) {
}
//tablet
@media only screen and (min-width: 740px) and (max-width: 1023px) {
}
//mobile
@media only screen and (max-width: 740px) {
    .input-item {
        width: 100% !important;
    }
}
