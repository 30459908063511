.table-common-antd {
    .ant-table {
        tbody {
            .ant-table-row {
                &.expand-parent {
                    // overflow: hidden;
                    td {
                        &:first-child {
                            border-bottom-left-radius: 0;
                        }

                        &:last-child {
                            border-bottom-right-radius: 0;
                        }
                        > div {
                            background-color: #ccdeff !important;
                        }
                    }
                }

                td {
                    border-bottom: none;
                }
            }

            .ant-table-expanded-row {
                position: relative;

                td {
                    padding: 0px;
                    background-color: #eff5ff !important;
                    border-bottom-right-radius: 8px;
                    border-bottom-left-radius: 8px;

                    .expand-row-container {
                        padding: 12px 36px;
                        max-width: 100%;
                    }
                }
            }

            // td.ant-table-cell-row-hover {
            //     background-color: #EFF5FF;
            // }
        }

        .ant-table-container {
            .ant-table-content table {
                border-collapse: separate;
                border-spacing: 0 !important;
                // margin-top: -12px;
            }
            .ant-table-measure-row {
                display: none;
            }
        }

        .ant-table-tbody > tr > td {
            border-bottom: none;
        }

        .ant-table-thead tr th:nth-child(1) {
            border-top-left-radius: 6px;
            padding-left: 12px;
        }

        .ant-table-thead tr th:nth-child(3) {
            padding-right: 40px;
        }

        .ant-table-thead tr th:nth-child(6) {
            padding-right: 26px;
        }

        .ant-table-thead tr th:last-child {
            border-top-right-radius: 6px;
        }

        .ant-table-thead > tr > th {
            background-color: #f6f6f6;
            border-bottom: none;
            padding: 5px 12px;
            margin-bottom: 12px;

            ::before {
                content: none;
            }
        }

        .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
            content: none;
        }

        .ant-table-tbody > tr > td:nth-child(2) {
            padding-left: 0;
        }
    }
    .tr-even {
        td {
            background-color: #ffffff;
            > div {
                background-color: #f6f6f6;
            }
        }
    }
    .ant-table-tbody {
        .ant-table-row {
            .ant-table-cell {
                // box-sizing: content-box;
                height: 68px;
                padding: 0;
                padding-top: 12px;
                background: #ffffff;
                > div {
                    height: 100%;
                    padding: 18px 12px;
                    @supports (-webkit-overflow-scrolling: touch) {
                        box-sizing: content-box;
                    }
                }

                &-row-hover {
                    > div {
                        background: #eff5ff !important;
                    }
                }
            }

            .ant-table-cell:nth-child(3) {
                > div {
                    padding-right: 40px;
                }
            }
        }
    }
    ._row-order {
        td:first-child {
            > div {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }
        }
        td:last-child {
            > div {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }
        }
        &.expand-parent {
            td:first-child {
                > div {
                    border-bottom-left-radius: 0;
                }
            }
            td:last-child {
                > div {
                    border-bottom-right-radius: 0;
                }
            }
        }
    }
}