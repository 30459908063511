.dropdown-custom {
    .ant-dropdown-menu {
        padding: 0;
        border-radius: 12px;
    }
    .ant-dropdown-menu-item:first-child {
        border-radius: 12px 12px 0 0;
    }
    .ant-dropdown-menu-item:last-child {
        border-radius: 0 0 12px 12px;
        border-bottom: none !important;
    }
    .ant-dropdown-menu-item {
        padding: 12px 16px;
        min-width: 320px;
        border-bottom: 1px solid #ebebeb;
    }
}
