.createform {
  width: 690px;
  margin: auto;
}

ol, ul {
  list-style: none; }

.width100 {
  width: 100%;
}

.dropzone {
  display: flex;
  flex-wrap: wrap;
}

.dropzone > div:not(.dropped-file) {
  position: relative;
  width: 70px!important;
  height: 70px!important;
  border: 1px dashed #dfe1e4!important;
  border-radius: 5px;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.dropzone > div:not(.dropped-file).ibizan-focus {
  border: 1px dashed #fc7056!important;
}


.dropzone > div:not(.dropped-file) > i {
  color: #dfe1e4;
}

.dropzone:hover {
  cursor: pointer;
}

.dropped-files {
  display: inline-block;
  padding: 0;
  margin: 0;
  vertical-align: middle;
}

.dropped-file {
  display: inline-block;
  list-style: none;
  border-radius: 5px;
  width: 120px;
  height: 120px;
  margin-bottom: 15px;
  background-position: center;
  background-size: cover;
  position: relative;
}

.remove-image-btn {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 15px;
  height: 15px;
  text-align: center;
  border-radius: 50%;
  background-color: #cccccc;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.dropped-file:hover .remove-image-btn {
  display: flex;
}
.remove-image-btn i {
  font-size: 15px;
  color: #fff;
}

.remove-image-btn:hover {
  background-color: #e91e63
}

//.camera-dropzone>div {
//  border: none!important;
//  width: unset!important;
//  height: unset!important;
//}

.txt-color-pink {
  color: #e91e63;
}