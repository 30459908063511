.boxform{
	width: 480px;
	min-height: 300px;
	@include box-shadow(0px, 1px, 22px, 0px, rgba(0,0,0,0.25));
	padding: 40px 60px;
	box-sizing: border-box;
	@include border-radius(15px);
	&__content{
		ul{margin: 0;}
		input[type="text"], input[type="password"], input[type="email"]{
			width: 100%;
			height: 40px;
			line-height: 40px;
			border: 1px solid #D9D9D9;
			@include border-radius(3px);
			padding: 0 20px 0 40px;
			box-sizing: border-box;
			&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			  font-size: 14px;
			  color: #D9D9D9;
			  font-family: $robotofont;
			  font-weight: 300;
			}
			&::-moz-placeholder { /* Firefox 19+ */
			  color: pink;
			}
			&:-ms-input-placeholder { /* IE 10+ */
			  color: pink;
			}
			&:-moz-placeholder { /* Firefox 18- */
			  color: pink;
			}
			&:focus{
				outline: 0
			}
		}
		a{
			text-decoration: none;
		}
		.iconip{
			top: 7px;
			left: 15px;
		}
		.errorip{
			input{
				border: 1px solid #CF1322;
			}
			.texterror{
				//margin-top: 2px;
			    bottom: -20px;
				left: 0;
			}
		}
		.btn--blue{
			width: 250px;
			height: 48px;
			line-height: 48px;
			@include border-radius(24px);
			font-size: $txt-size-h5;
			color: $txt-color-white;
			font-family: $robotofont;
			font-weight: 300;
			&:hover{
				color: var(--ant-primary-color);
			}
			&.disabled {
				cursor: no-drop;
				opacity: 0.6;
			}
		}
	}
	&__btnsocial{
		margin-top: 50px!important;
		padding: 0;
		.btn-social {
			height: 32px;
			line-height: 32px;
			outline: none;
			box-shadow: none;
			border-style: unset;
			@include border-radius(20px);
			padding: 0 20px 0 30px;
			img{
				position: absolute;
				top: 8px;
				left: 16px;

			}
		}
		.btnsocial--fb{
			background-color: #3B5998;
		}
		.btnsocial--gg{
			background-color: #D34836;
			img{
				top: 11px;
				left: 14px;

			}
		}
	}
}
.bdor{
	&:before{
		content:"";
		position: absolute;
		width: 100%;
		height: 1px;
		background-color: rgba(0,0,0, 0.25);
		left: 0;
		top: 7px;
	}
	span{
		margin-left: -26px;
	}
}

//@media only screen and(max-width: 414px){
//	.boxform{
//		width: 300px;
//		margin-right: -150px;
//		padding: 40px 20px;
//		&__content{
//			ul{
//				li{
//					//margin-bottom: 25px;
//				}
//			}
//		}
//	}
//
//}










