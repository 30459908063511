.btn{
	&--transparent{
		background-color: transparent;
		color: #9E9E9E;
	}
	&--white{
		height: 32px;
		line-height: 24px;
		background-color: $bg-color-white;
		color: var(--ant-primary-color);
		font-size: $txt-size-h7;
		font-weight: 400;
		border: 1px solid var(--ant-primary-color);
		&:hover{
			background-color: var(--ant-primary-color);
			border: 1px solid var(--ant-primary-color);
			color: $txt-color-white;
		}
	}
	&--white2{
		height: 32px;
		line-height: 32px;
		background-color: $bg-color-white;
		color: $txt-color-gray;
		font-size: $txt-size-h8;
		@include box-shadow(-1px, 0px, 13px, 0px, rgba(0,0,0,0.1));
		@include border-radius(4px);
		padding: 0 20px;
		&:hover{
			color: $txt-color-blue;
		}
	}
	&__delete{
		width: 30px;
		height: 30px;
		border: 1px solid #D9D9D9;
		color: #898989;
		font-size: $txt-size-h7;
		position: absolute;
		right: -10px;
		text-align: center;
		line-height: 30px;
		background-color: #ffffff;
		@include border-radius(4px);
	}
	&__collapseservice{
		background-color: #F5F5F5;
		border: 1px solid #E9E9E9;
		padding: 3px;
		text-align: center;
		margin-bottom: 10px;
	}
	&--blue{
		height: 40px;
		line-height: 32px;
		background-color: var(--ant-primary-color);
		color: $txt-color-white;
		font-size: $txt-size-h7;
		font-weight: 400;
		border: 1px solid var(--ant-primary-color);
		&:hover{
			background-color: $bg-color-white!important;
			border: 1px solid var(--ant-primary-color);
			color: var(--ant-primary-color);
		}
	}
	&--peer-payment-pay {
		border-radius: 4px;
		height: 33px;
	}
	&__status{
		height: 22px;
		line-height: 22px;
		background-color: $bg-color-blue;
		font-size: $txt-size-h8;
		font-family: $robotofont;
		font-weight: 400;
		border: 0;
		color: $txt-color-white;
		@include border-radius(32px);
		padding: 0 16px;
	}
}
.buttonm{
	height: 32px;
	line-height: 32px;
	border: 1px solid $bg-color-blue;
	background-color: $bg-color-white;
	font-size: 14px!important;
	color: $txt-color-blue;
	transition: .3s ease-in-out;
	align-content: center;
	align-items: center;
	display: flex;
	@include border-radius(4px);
	&:hover{
		background-color: $bg-color-blue;
		color: $txt-color-white;
	}
	&__cancel{
		border: 1px solid $bg-color-gray4;
		background-color: $bg-color-white;
		color: $txt-color-black;
		&:hover{
			border: 1px solid $bg-color-gray4;
			background-color: $bg-color-black3;
			color: $txt-color-white;
		}
	}
}
