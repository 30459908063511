.v2-popover {
    .ant-popover-arrow {
        display: none;
    }
    .ant-popover-inner {
        border-radius: 16px;
        box-shadow: 0px 7px 24px 0px rgba(17, 17, 17, 0.08), 0px 0px 6px 0px rgba(0, 0, 0, 0.05);

        .ant-popover-inner-content {
            padding: 16px 24px;
            min-width: 350px;
            max-width: 400px;
        }
    }
}
