.cartscontainer {
    .ant-list-header {
        padding-bottom: 0;
    }
    .headerlist {
        &__top {
            margin-bottom: 10px;
            //&_right{
            //	padding-top: 10px;
            //}
        }
        &__title {
            height: 40px;
            line-height: 40px;
            border: 1px solid #e9e9e9;
            border-bottom: 0;
            padding: 0 10px;
            background-color: $bg-color-gray;
        }
    }
    .ant-list-empty-text {
        display: none;
    }
    .ant-list-item {
        align-items: flex-start;
        padding: 15px;
        background-color: $bg-color-white;
        border: 1px solid #e9e9e9 !important;
        border-bottom-color: transparent;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        .btn__delete {
            display: none;
        }
        &:hover {
            .btn__delete {
                display: block;
            }
            border: 1px solid var(--ant-primary-color) !important;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
        }
        &.list-alert-error {
            padding: 5px 17px;
            background: #ffd5d6;
            border: 1px solid $txt-color-red2 !important;
        }
    }
    .ant-spin-container {
        //border: 1px solid #E9E9E9;
        //border-top: 0;
    }
}

.list-alert-warning {
    padding: 5px 15px;
    background: #fffbe6;
    border: 1px solid $txt-color-orange !important;
}

.ant-list-item {
    .btn__delete {
        display: none;
    }
    &:hover {
        .btn__delete {
            display: block;
        }
    }
}

.list-hover-item {
    .ant-list-item {
        align-items: flex-start;
        border: 1px solid #e9e9e9 !important;
        border-bottom-color: transparent;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        &:hover {
            border: 1px solid var(--ant-primary-color) !important;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
        }
        &.list-alert-warning {
            align-items: center;
        }
    }
}

.is-tablet {
    .ipcustom {
        input.ant-input-number-input {
            width: 70px;
        }
    }
}

.listcards {
    &__item {
        &:last-child {
            border: 1px solid $bg-color-gray !important;
        }
    }
}

.listdeal {
    .ant-table-thead > tr > th {
        padding: 9px 16px 6px;
        span {
            font-size: $txt-size-h7;
            font-family: $robotofont;
            font-weight: 400;
        }
    }
    &__financialamount {
        width: 135px;
    }
    &__financialtime {
        width: 200px;
    }
}

.wptablem {
    .ant-table {
        background-color: transparent;
        .ant-table-header {
            background-color: $bg-color-white;
            @include border-radius(6px);
            margin-bottom: 6px;
            thead {
                th {
                    background-color: transparent;
                    border: none !important;
                    color: $txt-color-black2;
                    font-size: $txt-size-h8;
                    font-weight: 500;
                }
            }
        }
        .ant-table-body {
            @include border-radius(6px);
            background-color: $bg-color-white;
            tbody {
                tr {
                    td {
                        border: none !important;
                        color: $txt-color-black2;
                        font-size: $txt-size-h8;
                    }
                }
            }
        }
    }
}
.totalitempage {
    background-color: #ddf0ff;
    padding: 3px 10px;
    border-radius: 100%;
    font-size: 12px;
    color: var(--ant-primary-color);
}
.fa-trash {
    &:hover {
        color: $txt-color-red;
    }
}

.table-responsive {
    width: 100%;
    overflow-x: auto;
}

.table-v2 {
    table {
        border-spacing: 0 16px;
    }
    .ant-table-thead > tr > th {
        padding: 6px 16px;
        border-bottom: none;
    }
    .ant-table-thead > tr > th:first-child {
        border-top-left-radius: 6px !important;
    }
    .ant-table-thead > tr > th:last-child {
        border-top-right-radius: 6px !important;
    }
    .ant-table-thead .ant-table-cell {
        color: #7d7e7e;
        font-size: 12px;
    }

    .ant-table-tbody > tr > td {
        border-bottom: none;
    }
    .ant-table-row {
        border-radius: 12px;
        td:first-child {
            border-radius: 12px 0 0 12px;
        }
        td:last-child {
            border-radius: 0 12px 12px 0;
        }
    }
    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
        background: #f6f6f6;
    }
    .table-row-odd {
        border-radius: 12px;
        background: #f6f6f6;
        td:first-child {
            border-radius: 12px 0 0 12px;
        }
        td:last-child {
            border-radius: 0 12px 12px 0;
        }
    }
    .ant-table-expanded-row-level-1 {
        position: relative;
        top: -16px;
        & > td {
            background: #fff !important;
            border: 1px solid #1890ff !important;
            border-top: none !important;
            border-radius: 0 0 12px 12px;
            // padding: 16px 0px 8px 16px;
        }
    }
    .table-row-expanded {
        td {
            border-top: 1px solid #1890ff;
        }
        td:first-child {
            border-radius: 12px 0 0 12px;
            border-left: 1px solid #1890ff;
        }
        td:last-child {
            border-radius: 0 12px 12px 0;
            border-right: 1px solid #1890ff;
        }
    }

    .table-row-expanded {
        border-radius: 12px !important;
        background: #ddeeff !important;
        td {
            background: #ddeeff !important;
        }
        td:first-child {
            border-radius: 12px 0 0 0 !important;
        }
        td:last-child {
            border-radius: 0 12px 0 0 !important;
        }
    }
    .ant-table-wrapper::before,
    .ant-table-wrapper::after {
        display: none;
    }
}

.table-inside {
    table {
        border-spacing: 0px;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
        background: #fff;
    }
    .ant-table-thead > tr > th {
        background: #fff;
        color: #1a1a1a;
        border-bottom: none;
    }
    .ant-table {
        margin: 0px 0px 0px 32px !important;
    }
    .ant-table-tbody > tr > td {
        border-bottom: 1px solid #ebebeb;
        border-radius: 0 !important;
    }
}
