.tab-common-antd {
    .ant-tabs-nav {
        margin-bottom: 24px;
    }

    .ant-tabs-tab {
        .ant-tabs-tab-btn {
            color: #7d7e7e;
            font-size: 16px;
            &:hover {
                color: #1890ff;
            }
        }
    }

    .ant-tabs-tab.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
            color: #1890ff;
        }
    }
}
