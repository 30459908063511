.sidebarleft{
	position: absolute;
	//height: 100%;
	bottom: 0;
	top: 0;
	background-color: var(--ant-primary-color);
	z-index: 999;
	&__logo{
		padding-top: 30px;
		padding-left: 15px;
		margin-bottom: 30px;
		img{
			max-width: 100px;
			max-height: 33px;
		}
	}
	&__menu{
		background-color: var(--ant-primary-color);
		border: 0;
		li{
			padding-left: 15px!important;
			padding-right: 15px!important;
			.ant-menu-submenu-title{
				padding-left: 20px!important;
				i{
					//right: 0;
					&:before, &:after{
						background-image: linear-gradient(to right, rgba(225, 225, 225, 1), rgba(225, 225, 225, 1))!important;
					}
				}
			}
			span{
				color: $bg-color-white;
				font-size: $txt-size-h7;
				font-family: $robotofont;
				font-weight: 400;
			}
			i{
				font-size: 17px!important;
				color: $txt-color-white;
			}
			/*&:hover{
				background-color: $bg-color-blue2;
			}*/
		}
		li.ant-menu-item-selected, li.ant-menu-item-active{
			background-color: var(--ant-primary-color-active)!important;
			&:after{
				width: 0!important;
			}
		}
		.ant-menu-submenu{
			padding: 0!important;
			.ant-menu-submenu-title{
				padding-left: 15px!important;
				padding-right: 15px;
			}
			li.ant-menu-item{
				padding-left: 42px!important;
				font-size: $txt-size-h7;
				color: $txt-color-white;
				font-family: $robotofont;
				font-weight: 400;
			}
			.ant-menu-sub{
				background-color: var(--ant-primary-color);
				li{
					a{
						font-size: $txt-size-h7;
						color: $txt-color-white;
						font-family: $robotofont;
						font-weight: 400;
					}
				}
			}
		}
	}
}

.sidebar{
	//border: 1px solid #E9E9E9;
	.ant-collapse{border: 0;}
	.ant-collapse-header{
		color: rgba(0,0,0,.85);
		font-weight: 500;
		font-size: $txt-size-h7;
		@include border-radius(0);
		-webkit-border-top-left-radius: 4px;
		-webkit-border-top-right-radius: 4px;
		-moz-border-radius-topleft: 4px;
		-moz-border-radius-topright: 4px;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}
	&__financialinfo{
		background-color: #E6F7FF;
		border: 1px solid #91D5FF;

	    &_box{
	    	padding: 20px 20px 25px;
	    	.ant-divider{
    			background: #91D5FF;
	    	}
	    }

		&.warning {
			background-color: #fff1f0;
			border: 1px solid #f7cac9;
			.ant-divider{
				background: #f7cac9;
			}
		}

	}
	.ant-divider-horizontal{
		margin: 20px 0;
		opacity: .4;
	}
	textarea{
		height: 30px;
		border: 1px solid #D9D9D9;
		background-color: $bg-color-white;
		resize: none;
		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		  color: $txt-color-gray;
		  font-size: $txt-size-h7;
		  font-family: $robotofont;
		  font-weight: 400;
		}
		&::-moz-placeholder { /* Firefox 19+ */
		  color: $txt-color-gray;
		  font-size: $txt-size-h7;
		  font-family: $robotofont;
		  font-weight: 400;
		}
		&:-ms-input-placeholder { /* IE 10+ */
		  color: $txt-color-gray;
		  font-size: $txt-size-h7;
		  font-family: $robotofont;
		  font-weight: 400;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: $txt-color-gray;
		  font-size: $txt-size-h7;
		  font-family: $robotofont;
		  font-weight: 400;
		}
		&:hover{
			border: 1px solid #FFFFFF;
		}
	}
	&__title{
		background-color: #FAFAFA;
		height: 40px;
		line-height: 40px;
		padding-left: 20px;
		border-bottom: 1px solid #E9E9E9;
		-webkit-border-top-left-radius: 3px;
		-webkit-border-top-right-radius: 3px;
		-moz-border-radius-topleft: 3px;
		-moz-border-radius-topright: 3px;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
	}
}
.servicesbox{
	&__content{
		background-color: $bg-color-white;
		padding: 20px;
	}
}
.deliveryaddress{
	@include box-shadow(0px, 0px, 5px, 0px, rgba(0,0,0,0.05));
	@include border-radius(3px);
	border: 0;
	&__box{
		border: 2px dotted #D9D9D9;
		@include border-radius(5px);
		padding-top: 10px;
	}
	.ant-collapse-item{
		@include border-radius(3px);
		border-bottom: 0;
	}
	.ant-collapse-content{
		background-color: $bg-color-white;
		border-top: 0;
		&-box{
			padding-top: 0;
		}
	}
	.ant-collapse-header{
		background-color: $bg-color-white;
		border: 0;
		-webkit-border-top-left-radius: 4px!important;
		-webkit-border-top-right-radius: 4px!important;
		-moz-border-radius-topleft: 4px!important;
		-moz-border-radius-topright: 4px!important;
		border-top-left-radius: 4px!important;
		border-top-right-radius: 4px!important;
	}
	&__edit{
		top: 15px;
		right: 22px;
	}
	&__name{
		&:before{
			content: "";
			position: absolute;
			width: 20px;
			height: 1px;
			background-color: #D9D9D9;
			bottom: -6px;
		}
	}
}
.menu-collapsed{
	.sidebarleft{
		flex: 0 0 60px!important;
		max-width: 60px!important;
		min-width: 60px!important;
		width: 60px!important;
		&__menu{
			width: 60px;
		}
		&__logo{
			&_menu{
				display: none;
			}
			&_collapsed{
				display: block!important;
				img{
					width: 31px;
				}
			}
		}
		.ant-menu-submenu{
			padding-left: 15px!important;
		}
	}
}
.sidebarleft{
	max-width: 218px!important;
	min-width: 218px!important;
	width: 218px!important;
}
.is-tablet{
	.sidebarleft{
		&__menu{
			li{
				padding-left: 10px !important;
				padding-right: 10px !important;
			}
		}
	}
	.list-cartsipad{
		padding-right: 0;
	}

	.sidebarleft__menu{
		.ant-menu-submenu{
			.ant-menu-submenu-title{
				padding-left: 0px!important;
			}
		}
	}


	.ant-layout-sider-collapsed{
		.sidebarleft__menu{
			.ant-menu-submenu{
				.ant-menu-submenu-title{
					padding-left: 5px!important;
				}
			}
		}
	}

}
.ant-layout-sider-collapsed{
	.sidebarleft__menu{
		.ant-menu-item{
			padding-left: 20px!important;
		}
		.ant-menu-submenu{
			.ant-menu-submenu-title{
				padding-left: 5px!important;
			}
		}
	}
}







