.boxservices{
	&__item{
		padding-right: 10px;
		&:before{
			content: "";
			position: absolute;
			width: 1px;
			height: 12px;
			background-color: $bg-color-gray2;
			right: 0;
			top: 2px;
		}
		&:first-child{
			padding-left: 0;
		}
		&:last-child{
			&:before{
				height: 0;
			}
		}
	}
	&__lbl{
		width: 70px;
		padding-top: 1px;
	}
}

.cart-deposit-tabs {
	.ant-tabs-nav {
	  margin-bottom: 0;
	}
  .ant-tabs-tab {
	background-color: #fff;
	margin-left: 2px;
	border-radius: 4px 4px 0 0;
	border: 1px solid #91d5ff;
	&.ant-tabs-tab-active {
	  background-color: #e6f7ff;
	}
	&:first-child {
	  margin-left: 0;
	}
  }
}
