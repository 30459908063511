.custom-pagination {
    .ant-pagination {
      .ant-pagination-item, .ant-pagination-jump-next, .ant-pagination-jump-prev {
        display: none;
      }
      .ant-pagination-prev, .ant-pagination-next {
        margin: 0;
        button {
          border-color: transparent;
          background-color: transparent;
          span {
            font-weight: 500;
            font-size: 14px;
          }
        }
      }
    }
  }
  