$secondary-color: var(--ant-primary-color);

.v2-layout {
    textarea.ant-input {
        border-color: #c0c0c0;
        border-radius: 8px !important;

        &:hover,
        &:focus {
            border-color: $secondary-color !important;
        }
    }
    .ant-input-affix-wrapper {
        border-color: $gray-color;
    }
}
.form-field {
    position: relative;
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(
            .ant-input-affix-wrapper-borderless
        ).ant-input-affix-wrapper,
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(
            .ant-input-affix-wrapper-borderless
        ).ant-input-affix-wrapper:hover {
        background: #fff;
        border-color: #ff4d4f !important;
    }

    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
        background: #fff;
        border-color: #ff4d4f !important;
        border-color: var(--ant-error-color) !important;
    }

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        // border-color: $secondary-color !important;
        border-right-width: 1px;
        z-index: 1;
    }

    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
        border-color: $secondary-color !important;
        box-shadow: 0 0 0 2px RGB(24 144 255 / 20%);
        border-right-width: 1px;
        outline: 0;
    }
    .ant-input-affix-wrapper {
        border-color: #c0c0c0;
        padding-left: 16px;
        input {
            height: unset;
            line-height: unset;
        }
    }

    .ant-input-affix-wrapper,
    textarea {
        border-radius: 8px !important;
    }

    .form-label {
        color: #7d7e7e;

        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);

        user-select: none;
        pointer-events: none;
        transition: 0.25s ease;
        z-index: 10;
    }

    .form-input {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .form-input:focus {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .form-input:not(:placeholder-shown) {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .form-input:not(:focus-within) + .form-label {
        color: #7d7e7e;
    }

    // .form-input:not(:placeholder-shown) + .form-label,
    .form-input:focus + .form-label,
    .ant-input-affix-wrapper-focused + .form-label,
    .input-has-value + .form-label {
        color: $secondary-color;
        font-size: 12px;
        font-weight: 400;
        top: 0px;
        background: #ffff;
        padding: 0px 4px;
    }

    .input-has-value .ant-input-suffix {
        margin-top: -2px;
    }
}

.form-field-password {
    position: relative;

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        // border-color: $secondary-color !important;
        border-right-width: 1px;
        z-index: 1;
    }
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
        border-color: $secondary-color !important;
        box-shadow: 0 0 0 2px RGB(24 144 255 / 20%);
        border-right-width: 1px;
        outline: 0;
    }
    .ant-input-password {
        border-radius: 8px;
    }
    .ant-input-affix-wrapper {
        border-color: #c0c0c0;
        padding-left: 8px;
        input {
            height: unset;
            line-height: unset;
        }
    }

    .form-label {
        color: #7d7e7e;

        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);

        user-select: none;
        pointer-events: none;
        transition: 0.25s ease;
        z-index: 10;
    }

    .form-input {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .form-input:focus {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .form-input:not(:placeholder-shown) {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .form-input:not(:focus-within) + .form-label {
        color: #7d7e7e;
    }

    // .form-input:not(:placeholder-shown) + .form-label,
    .form-input:focus + .form-label,
    .ant-input-affix-wrapper-focused + .form-label,
    .input-has-value + .form-label {
        color: $secondary-color;
        font-size: 12px;
        font-weight: 400;
        top: 0px;
        background: #ffff;
        padding: 0px 4px;
    }

    .input-has-value .ant-input-suffix {
        margin-top: -2px;
    }
}
.PhoneInputInput {
    border: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

.PhoneInput {
    &.PhoneInput--focus {
        border: 0;
        .PhoneInputInput {
            outline: 0;
            border-color: $secondary-color;
        }
    }
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
    border-color: #ff4d4f !important;
}
