.form-select,
.form-textarea {
    width: 100%;

    .float-label {
        position: relative;
    }

    .label {
        position: absolute;
        pointer-events: none;
        left: 16px;
        top: 10px;
        transition: 0.2s ease all;
    }

    .label-float {
        font-size: 12px;
        color: var(--ant-primary-color) !important;
        font-weight: 400;
        top: -7px;
        background: #ffff;
        padding: 0px 4px;
    }

    .float-label:not(.float-label--focused) .label-float {
        color: #7d7e7e !important;
    }

    .ant-input {
        padding: 10px 16px;
        // padding: 16px 12px 4px 11px;
    }

    .ant-select .ant-select-selector {
        // padding: 16px 10px 4px 11px;
    }

    .ant-select-clear {
        margin-top: -8px;
    }

    .ant-select-multiple .ant-select-selection-item-remove .anticon-close svg:hover {
        color: #ff4559;
    }

    .ant-select-multiple .ant-select-selector {
        height: auto !important;
        min-height: 44px;
    }

    .ant-select-multiple .ant-select-selection-search-input,
    .ant-select-multiple .ant-select-selection-search-mirror {
        height: 20px;
        line-height: 20px;
    }

    .ant-select-multiple .ant-select-selector,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        padding: 6px 10px 4px 11px;
        border-radius: 6px;
        border-color: #c0c0c0;
        height: 44px;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-search {
        top: 6px;
    }
}