.v2-steps {
    .ant-steps-item {
        .ant-steps-item-container {
            // position: relative;
            min-height: 80px;
            .ant-steps-item-content {
                top: 0;
            }
            .ant-steps-item-icon {
                position: absolute;
                top: 60px;
            }
            .ant-steps-item-tail {
                top: 70px;
            }
        }
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
        display: none;
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
        display: none;
    }
    .ant-steps-item-active {
        .ant-steps-item-title {
            color: #1890ff !important;
        }
    }
}
