.sidebarprofile{
  padding-top: 45px;
  &__info{
    top: -42px;
    left: 0;
    right: 0;
    //left: 50%;
    //transform: translateX(-50%);
    &_avt{

    }
  }
  &__menu{
    border: 0;
    li{
      padding: 0 25px!important;
    }
    li.ant-menu-item-selected{
      position: relative;
      &:before{
        content: "";
        position: absolute;
        width: 3px;
        height: 100%;
        right: 0;
        background-color: $bg-color-blue;
      }
    }
  }
}
.profiletableth{
  >div{
    font-size: $txt-size-h7;
    color: $txt-color-black;
    font-family: $robotofont;
    font-weight: 400;
  }
}
.formChangeUserProfile{
  &__username{
    &_wp{
      margin-top: -6px;
      .ant-form-item-control{
        line-height: 30px;
      }
    }
    &_icon{
      top: 0;
      right: -27px;
      padding: 4px 8px 5px 7px;
      -webkit-border-top-right-radius: 4px;
      -webkit-border-bottom-right-radius: 4px;
      -moz-border-radius-topright: 4px;
      -moz-border-radius-bottomright: 4px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &_ip{
      height: 30px;
    }
  }
  &__gender{
    .ant-select{
      width: 200px;
      margin-top: -5px;
    }
  }
}

._save_profile {
  cursor: pointer;
}