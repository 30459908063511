.v2-layout .pagination-custom-v2 {
    .ant-pagination-options {
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border-radius: 4px;
        }
    }

    .ant-pagination-item {
        border-radius: 4px;

        &:hover {
            background: $secondary-color;
            border-color: $secondary-color;

            a {
                color: $bg-color-white;
            }
        }
    }

    .ant-pagination-item-active {
        background: $secondary-color;
        border-color: $secondary-color;

        &:hover {
            a {
                color: $bg-color-white;
            }
        }

        a {
            color: $bg-color-white;
        }
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background: $secondary-color;
            border-color: $secondary-color;
            color: $bg-color-white;
        }

        &:disabled {
            background: $bg-color-gray4;
            border-color: $light-color;
            color: $txt-color-disabled;
        }
    }

    .ant-select-focused:not(.ant-select-disabled) {
        &.ant-select:not(.ant-select-customize-input) {
            .ant-select-selector {
                border-color: $secondary-color;
                box-shadow: 0px 0px 0px 2px #e7effe;

                @supports (-webkit-overflow-scrolling: touch) {
                    border-color: #c0c0c0;
                    box-shadow: none;
                }

                @supports (-webkit-touch-callout: none) {
                    border-color: #c0c0c0;
                    box-shadow: none;
                }
            }
        }
    }

    .ant-select:not(.ant-select-disabled) {
        &.ant-select-open {
            .ant-select-selector {
                border-color: $secondary-color !important;
                box-shadow: 0px 0px 0px 2px #e7effe;
            }
        }

        &:hover {
            .ant-select-selector {
                border-color: $secondary-color;
                box-shadow: 0px 0px 0px 2px #e7effe;
            }
        }
    }
}