.ticket_vertical {
  height: 3.9em;
}

.height500 {
  height: 500px;
}

.w100 {
  width: 100%;
}
.classDisabled {
  pointer-events: none;
  opacity: 0.5;
  background: #CCC;
}

.mgt-8 {
  margin-top: -8px !important;
}

.size16 {
  font-size: 36px;
}